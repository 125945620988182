<template>
  <div class="artist-text" :id="'artist-'+id">
    {{artist}}
    <b-popover
      ref="popover"
      :target="'artist-'+id"
      triggers="hover"
      :container="container"
      :delay="{show: 100, hide: 200}"
      @hide="onHide($event)"
      @show="onShow($event)"
      custom-class="mw-none"
    >
      <div @click.stop>
        <router-link
          :to="getTo()"
        >
          {{artist}}
        </router-link>
        <div v-if="loading" class="artist-related-image d-flex justify-content-center align-items-center">
          <b-spinner small class="text-secondary"/>
        </div>
        <div v-else class="artist-related-images">
          <router-link
            v-for="item in relatedFiltered"
            :key="item.id"
            class="artist-related-item"
            :to="getTo(item)"
          >
            <img class="artist-related-image bg-light"
              :src="item.thumb"
              alt=""
            >
          </router-link>
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
import { isGetty } from '@/components/MediaRelated.vue'
import { http } from '@/services/http.js'

export default {
  name: 'ArtistInfo',
  props: {
    item: Object,
    container: String,
  },
  data() {
    return {
      loading: false,
      popoverHover: false,
      related: [],
    }
  },
  computed: {
    artist() {
      return this.item.artist
    },
    id() {
      return this.item.id
    },
    relatedFiltered() {
      return this.related.slice(0,3)
    }
  },
  methods: {
    async getRelated() {
      if (this.related.length) { return }
      this.loading = true
      const params = {
        q: `"${this.artist}"`,
        api: (isGetty(this.item)) ? 'getty' : 'internal',
        limit: 3,
      }
      const response = await http.get('content/search/', {params})
        .finally(() => this.loading = false)
      this.related = response.data.images
    },
    onShow() {
      this.$refs.popover.$el.addEventListener('mouseover', this.onMouseover)
      this.$refs.popover.$el.addEventListener('mouseleave', this.onMouseleave)
      this.getRelated()
    },
    onHide(event) {
      if (this.popoverHover) {
        event.preventDefault()
      } else {
        this.$refs.popover.$el.removeEventListener('mouseover', this.onMouseover)
        this.$refs.popover.$el.removeEventListener('mouseleave', this.onMouseleave)
      }
    },
    onMouseover() {
      this.popoverHover = true
    },
    onMouseleave() {
      this.popoverHover = false
      setTimeout(() => {
        this.$refs.popover.$emit('close')
      })
    },
    getTo(item) {
      return {
        name: 'search',
        query: {q: `"${this.artist}"`},
        hash: item?.id ? `#${item.id}` : undefined,
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .artist-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .artist-related-images {
    white-space: nowrap;
  }
.artist-related-item {
  margin: 5px 0;
  & + & {
    margin-left: 5px;
  }
}
  .artist-related-image {
    height: 80px;
  }
</style>
