import { kebabCase } from 'lodash'

export default [
  {
    path: 'artist-identity/:token',
    component: {render(h) { return h('router-view') }},
    meta: { title: 'Manange Artist Payment - Great American Art' },
    children: [
      {
        path: ':type',
        name: 'manage-type-payment',
        component: () => import(/*webpackChunkName: 'artist' */ '@/pages/manage/artist-identity/_token/_type/index.vue'),
        props: route => ({
          ...route.params,
          ...route.query,
          fields: [
            {key: 'account', formatter: getPaymentAccount},
            {key: 'default', formatter: v => v ? '✓' : ''},
            {key: 'type', sortable: true, class: 'd-none d-sm-table-cell'},
            {key: 'status', sortable: true, class: 'd-none d-sm-table-cell'},
            {key: 'createdOn', sortable: true, class: 'd-none d-sm-table-cell'},
          ],
        }),
      },
      {
        path: ':type/new',
        name: 'manage-payment-new',
        component: () => import(/*webpackChunkName: 'artist' */ '@/pages/manage/artist-identity/_token/_type/new.vue'),
        props: true,
      },
      {
        path: ':type/:id',
        name: 'manage-payment',
        component: () => import(/*webpackChunkName: 'artist' */ '@/pages/manage/artist-identity/_token/_type/_id/index.vue'),
        props: route => ({
          ...route.params,
          nameKey: getPaymentNameKey(route.params.type),
        }),
      },
      {
        path: ':type/:id/edit',
        name: 'manage-payment-edit',
        component: () => import(/*webpackChunkName: 'artist' */ '@/pages/manage/artist-identity/_token/_type/_id/edit.vue'),
        props: route => ({
          ...route.params,
          nameKey: getPaymentNameKey(route.params.type),
          idKey: 'token',
          type: `artist-identity/${route.params.token}/${route.params.type}`
        }),
      },
      {
        path: ':type/:id/status-transitions',
        name: 'manage-type-status-transition',
        component: () => import(/*webpackChunkName: 'artist' */ '@/pages/manage/artist-identity/_token/_type/_id/status-transitions/index.vue'),
        props: route => ({
          ...route.params,
          ...route.query,
          nameKey: 'token',
        }),
      },
      {
        path: ':type/:id/status-transitions/:stsToken',
        name: 'manage-status-transition',
        component: () => import(/*webpackChunkName: 'artist' */ '@/pages/manage/artist-identity/_token/_type/_id/status-transitions/_stsToken.vue'),
        props: route => ({
          ...route.params,
          nameKey: 'token',
        }),
      },
    ],
  },
]

const paymentTypes = {
  'bank-accounts': 'bankAccountId',
  'paypal-accounts': 'email',
  'venmo-accounts': 'accountId',
}
function getPaymentNameKey(type) {
  return paymentTypes[type] ?? 'token'
}

function getPaymentAccount(value, key, item) {
  const type = `${kebabCase(item.type)}s`
  return item[getPaymentNameKey(type)]
}
