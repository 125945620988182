/**
 * Accumulate Batch
 * Accumulate a list of items and execute asynchronously, then executes callback
 * from last invocation
 * e.g.
 * accumulateBatch('my-id', 'Alice', console.log)
 * accumulateBatch('my-id', 'Bill', console.log)
 * → 'Alice, Bill'
 */

const accumulators = {}

export function accumulateBatch(message, item, callback, wait = 0) {
  if (!accumulators[message]) {
    accumulators[message] = { items: [] }
  }
  const acc = accumulators[message]
  clearTimeout(acc.timeout)
  acc.items.push(item)
  acc.timeout = setTimeout(() => {
    callback(acc.items)
    delete accumulators[message]
  }, wait)
}
