<template>
  <div>
    <div ref="scriptContainer"></div>

    <vue-google-autocomplete
      v-if="loaded"
      v-bind="$attrs"
      v-model="label"
      ref="address"
      classname="form-control"
      :class="{'is-valid': state === true, 'is-invalid': state === false}"
      placeholder=""
      @placechanged="getAddressData"
      @no-results-found="onNoResults"
      @blur="onBlur"
      @keydown.native.enter.prevent
      :types="types"
    />
    <input
      v-else
      v-model="label"
      ref="address"
      @blur="onBlur"
      type="text"
      class="form-control"
      :class="{'is-valid': state === true, 'is-invalid': state === false}"
    >

    <div v-if="value" class="small form-text">
      <div class="d-inline-block align-middle">
        <a :href="url" target="_blank" class="text-reset partial-underline">
          📍<span class="text">{{name}}</span>
        </a>
      </div>
      <button type="button"
        @click="clear"
        class="btn btn-micro btn-light rounded-pill ml-2 border-0"
      >
        &times;
      </button>
    </div>

  </div>
</template>

<script>
import VueGoogleAutocomplete from '@/components/VueGoogleAutocomplete.vue'
import { addScriptOnce } from '@/features/HyperwalletWidgets/utils/script.js'

export default {
  name: 'InputLocation',
  components: {
    VueGoogleAutocomplete,
  },
  inheritAttrs: false,
  props: {
    value: Object,
    state: Boolean,
  },
  data() {
    return {
      loaded: false,
      types: '', // override VueGoogleAutocomplete types default
      label: null,
    }
  },
  computed: {
    name() {
      if (!this.value) { return }
      return this.value.name || `${this.value.latitude}, ${this.value.longitude}`
    },
    url() {
      if (!this.value) { return }
      return this.value.url || `https://google.com/maps/?q=${this.value.latitude},${this.value.longitude}`
    },
  },
  watch: {
    value: 'setLabel',
    label: 'onInput',
  },
  async mounted() {
    this.setLabel(this.value)
    try {
      await this.loadMapsApi()
      this.loaded = true
    } catch (err) {
      this.$emit('error', new Error('Google Maps API error'))
    }
  },
  methods: {
    loadMapsApi() {
      if (!window.google) { // only load once
        return addScriptOnce(this.$refs.scriptContainer, {
          src: `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GM_API_KEY}&libraries=places`,
        })
      }
    },
    setLabel(val) {
      this.label = val ? val.label : ''
    },
    /**
    * When the location found
    * @param {Object} addressData Data of the found location
    * @param {Object} placeResultData PlaceResult object
    * @param {String} id Input container ID
    */
    getAddressData(addressData, placeResultData, id) {
      const el = document.getElementById(id)
      el.value = removeUSA(el.value)
      const { formatted_address, name, place_id, url } = placeResultData
      const data = {
        label: el.value,
        name,
        ...addressData,
        formatted_address,
        place_id,
        url,
      }
      this.$emit('input', data)
    },
    onInput(newVal) {
      if (this.value) {
        this.value.label = newVal
      }
    },
    onBlur() {
      if (!this.value) {
        this.label = ''
      }
    },
    onNoResults() {
      this.$emit('input', null)
    },
    clear() {
      this.$emit('input', null)
      this.$refs.address.focus()
    },
  }
}
function removeUSA(val) {
  if (!val) { return val }
  return val.replace(/, USA$/, '')
}
</script>
