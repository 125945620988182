<template>
  <div>
    <div class="search-option">
      <div class="title">
        <label for="quick-add-substrate" class="m-0">Substrate</label>
      </div>
      <Multiselect
        id="quick-add-substrate"
        v-model="selected.substrate"
        track-by="value"
        label="label"
        placeholder="Select"
        :options="substrateOptions"
        :loading="substratesLoading"
        :show-labels="false"
        :preselect-first="false"
        :allow-empty="false"
        @input="onSelectSubstrate"
      ></Multiselect>
    </div>
    <div class="search-option">
      <div class="title">
        <label for="quick-add-size" class="m-0">Size in inches</label>
      </div>
      <Multiselect
        id="quick-add-size"
        v-model="selected.size"
        track-by="label"
        placeholder="Select"
        :options="sizeOptions"
        :loading="sizesLoading"
        label="label"
        :preselect-first="false"
        :show-labels="false"
        :allow-empty="false"
      ></Multiselect>
    </div>
    <div class="search-option" v-show="profile">
      <div class="title">
        <label for="quick-add-workspace" class="m-0">Project</label>
      </div>
      <QuickAddWorkspace
        id="quick-add-workspace"
        ref="workspaceInput"
        v-model="selected.workspace"
        :profile="profile"
        :busy.sync="workspacesLoading"
      />
    </div>
    <div class="mt-3">
      <LoadingButton
        variant="primary"
        class="mr-2 mb-2"
        @click="add(item)"
        :busy="busy === 'add'"
        :disabled="disabled"
      >Add {{profile ? 'to Project' : 'to Cart'}}</LoadingButton>
      <button
        type="button"
        class="btn btn-link mb-2"
        :disabled="disabled"
        @click="open(item)"
      >Customize</button>
      <b-spinner small
        class="ml-2 align-baseline"
        v-show="!profile && workspacesLoading"
      />
    </div>
    <QuickModal
      id="modal-anon"
      title="Enter your email to save your cart"
      ref="modalAnon"
      centered
    >
      <AnonUserForm
        ref="formAnon"
        @submit="$refs && $refs.modalAnon.hide('ok')"
      ><span/></AnonUserForm>
      <div class="form-row mt-n3">
        <div class="col offset-lg-3 offset-sm-4 text-muted">
          <small>If you already have an account, <router-link :to="{name: 'accounts-login', query: {redirect: $route.fullPath}}">sign in</router-link> instead.</small>
        </div>
      </div>
    </QuickModal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapState, mapActions } from 'vuex'
import { get, startCase, memoize } from 'lodash'

import QuickAddWorkspace from '@/components/QuickAddWorkspace.vue'
import AnonUserForm from '@/components/AnonUserForm.vue'
import QuickModal from '@/components/QuickModal.vue'
import { API_URL, UI_URL } from '@/services/constants.js'
import { http, getAppApi } from '@/services/http.js'
import { getSrc } from '@/services/media.js'

export default {
  name: 'QuickAdd',
  components: {
    Multiselect,
    QuickAddWorkspace,
    AnonUserForm,
    QuickModal,
  },
  props: {
    item: Object,
    selected: {
      type: Object,
      default: () => ({
        substrate: null,
        size: null,
        workspace: null,
      })
    },
  },
  data() {
    return {
      substrates: [
      ],
      sizes: {
      },
      substratesLoading: false,
      sizesLoading: false,
      workspacesLoading: false,
      busy: false,
      appApi: getAppApi(),
      // This component interacts with external state through this.selected and
      // this.$SmartProgress. this.foreground supports graceful interaction with that state.
      foreground: true,
    }
  },
  computed: {
    ...mapState('user', {
      user: 'user',
      profile: 'profile',
    }),
    cart: {
      get() {
        return this.$store.state.cart.cart
      },
      set(val) {
        this.$store.commit('cart/setCart', val)
      }
    },
    substrateOptions() {
      return this.substrates?.map(i => ({
        label: startCase(i),
        value: i,
        $isDisabled: !this.sizes[i]?.length
      }))
    },
    sizeOptions() {
      return this.getSizes(this.selected.substrate)
    },
    disabled() {
      return Boolean(this.busy)
          || !(this.selected.size && this.selected.substrate)
          || (!this.selected.workspace && this.workspacesLoading)
          || this.substratesLoading
          || this.sizesLoading
    },
  },
  watch: {
    item: {
      handler: 'onChange',
      immediate: true,
    }
  },
  created() {
    // component is no longer foreground if route change is initiated
    this.deregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      this.foreground = false
      next()
    })
  },
  destroyed() {
    this.foreground = false
    if (this.busy === 'open') {
      this.$SmartProgress.finish()
    }
    this.deregisterRouterGuard?.()
  },
  methods: {
    ...mapActions('user', [
      'fetchProfile',
    ]),
    getAnonEmail() {
      return this.$refs?.modalAnon.getValue(() => this.$refs?.formAnon.validate())
        .then(data => data.email)
    },
    async onChange(item) {
      this.$refs.workspaceInput && this.$refs.workspaceInput.onChange()
      await this.loadSizes(item)
      if (!this.foreground) { return } // don't interfere with other instances
      this.updateSelection()
    },
    updateSelection() {
      this.selected.substrate = this.getSubstrate(this.selected.substrate)
      this.selected.size = this.getSize(this.selected.size);
    },
    onSelectSubstrate() {
      this.selected.size = this.getSize(this.selected.size)
    },
    resetChoices() {
      this.substrates = []
      this.sizes = {}
    },
    async loadSizes(item, onUpdate) {
      if (!item || !item.max_width) {
        this.resetChoices()
        return
      }

      this.substrates = item.substrates || []
      this.sizes = item.substrate_sizes || {}
      onUpdate?.()
      if (!item.substrates || !item.substrate_sizes) {
        this.substratesLoading = !item.substrates
        this.sizesLoading = !item.substrate_sizes
        return await sizesApiCached(item.max_width, item.max_height).then((response) => {
          if (!item.substrates) {
            this.substrates = response.data.substrates;
          }
          if (!item.substrate_sizes) {
            this.sizes = response.data.sizes;
          }
          onUpdate?.()
        }).catch(this.resetChoices)
        .finally(() => {
          this.substratesLoading = false
          this.sizesLoading = false
        })
      }
    },
    getSizes(substrate){
      // returns the list of sizes for a given substrate
      const result = this.sizes[substrate?.value]
      if (!result) { return [] }
      return result.map((s) => {
        return (s.width && s.height)
          ? {
            label: `${s.width} × ${s.height}`.replace(/\.00/g, ''),
            value: { w: s.width, h: s.height },
          }
          : { label: 'Custom', value: { w: 999999.0, h: 999999.0 } }
      })
    },
    getSize(size) {
      // returns a size object from list of available sizes
      return this.findSize(size)
        || this.sizeOptions[2]
        || this.sizeOptions[0]
    },
    findSize(size) {
      // returns matching size object if it exists in the list of available sizes
      // we need the exact object because the selection UI compares by reference
      if (!size) { return }
      return this.sizeOptions.find(i => i.label === size.label)
    },
    getSubstrate(substrate) {
      // returns a substrate from list of available substrates
      const substrates = this.substrateOptions
      return substrates.find(i => i.value === substrate?.value)
          || substrates.find(i => i.value === 'print')
          || substrates[0]
    },
    async save(item, action, {onStart} = {}){
      const { workspace } = this.selected
      const data = {
        imagedata: item,
        size: this.selected.size.value,
        substrate: this.selected.substrate.value,
        action: action,
        workspace_id: workspace?.id,
      }

      let token      = workspace?.project ?? this.cart?.token
      let project_id = workspace?.project ?? this.cart?.project?.id
      let url        = `${UI_URL}projects/${project_id}`

      // get email address from anon users
      let cart_email
      if (!token && !this.profile) {
        cart_email = await this.getAnonEmail()
      }

      // start progress UI
      if (onStart) { onStart() }

      try {
        // if no cart, create a new cart
        if (!token) {
          this.cart = await this.appApi.post('/api/v2/cart/', {cart_email})
            .then(response => response.data)
          if (!this.profile) {
            this.$store.commit('cart/setGuest', true)
          } else {
            // make cart owned by logged in user
            // TODO: set ownership automatically when creating cart, then remove this
            await this.appApi.post(`/api/v2/cart/${this.cart.token}/migrate-cart/`)
          }
          token      = this.cart?.token
          project_id = this.cart?.project?.id
          /* global dataLayer */
          dataLayer.push({
            'event': 'cart_create',
            'project_id': project_id,
          })
          /* global drift */
          drift.track('Cart create', {
            url,
            project_id,
          })
        }

        // add item to cart
        const response = await this.appApi.post(`/api/v2/cart/${token}/item/`, data)
        const item_id = response.data.id
        dataLayer.push({
          'event': `cart_${action}`, // e.g. cart_add, cart_open
          'item_id': item_id,
          'project_id': project_id,
        })
        drift.track(`Cart ${action}`, {
          url,
          project_id,
          item_id,
        })

        // report popular
        await http.patch(
          `/content/edit/${item.id}/`,
          {popular: true},
        ).catch(() => {})

        return {response, project_id, token}
      } catch (err) {
        this.$alert.simple(`${get(err, 'response.data.detail', err)}\nPlease try again. Contact customer support if this persists.`, {variant: 'danger'})
        throw err
      }
    },
    async add(item) {
      try {
        const {project_id, token} = await this.save(item, 'add', {
          onStart: () => this.busy = 'add',
        })
        await this.onAdded(item, project_id, token)
      } finally {
        this.busy = false
      }
    },
    async onAdded(item, project_id, token) {
      const pendingCart = this.updateCart(project_id, token)
      if (!this.cart) {
        // the toast link relies on knowing the current cart, so when we don't
        // have a cart, wait for the request where we may get one
        await pendingCart
      }
      this.toast(item, project_id)
      this.updateWorkspaces()
    },
    async updateCart(project_id, token) {
        if (!this.cart || this.cart?.project?.id === project_id) {
          this.cart = await this.appApi.get(`/api/v2/cart/${token}/`)
            .then(response => response.data)
          this.$store.dispatch('cart/fetchItems')
        }
    },
    toast(item, projectId) {
      const image = this.$createElement('img', {attrs: {src: getSrc(item, 'thumb')}, class: 'toast-image mr-2'})
      const message = `"${item.title}" added to ${this.profile ? 'Project' : 'Cart'}`
      const to = this.cart?.project?.id === projectId
        ? '/cart'
        : `/projects/${projectId}`
      this.$bvToast.toast(
        [image, message],
        {to, variant: 'success', noCloseButton: true, solid: true, bodyClass: 'd-flex align-items-center text-reset'}
      )
    },
    async updateWorkspaces() {
      if (!this.selected.workspace && this.$refs.workspaceInput) {
        if (this.user) {
          await this.fetchProfile().catch(() => {})
        }
        this.$refs.workspaceInput.loadWorkspaces()
      }
    },
    async open(item) {
      const { response, project_id, token } = await this.save(item, 'open', {
        onStart: () => {
          this.busy = 'open'
          this.$SmartProgress.start()
        },
      })
        .catch(err => {
          this.busy = false
          this.$SmartProgress.fail()
          throw err
        })
      if (this.foreground) {
        window.location = `${API_URL}art/design/${response.data.id}`
      } else {
        // if a link was clicked during the request, it implies the user no
        // longer wants the pending navigation, so notify instead
        await this.onAdded(item, project_id, token)
        this.busy = false
      }
    },
  },
}

function sizesApi(width, height) {
  return getAppApi().get(`/api/v1/availablesizes/?w=${width}&h=${height}`)
}
// Available sizes are based on ratio, so we only need one request per ratio
const sizesApiCached = memoize(sizesApi, (w, h) => {
  const ratio = w / h
  return isNaN(ratio) ? `${w},${h}` : ratio
})
</script>

<style lang="scss" scoped>
.search-option {
  text-align: left;
  & + & {
    margin-top: 0.25rem;
  }
  .title {
    font-size: 0.8em;
  }
}
</style>
