import { lowerCase, startCase } from 'lodash'

import { formatCount } from '@/utils/count.js'
import { price } from '@/utils/price.js'

export default {
  install(Vue) {
    Vue.filter('formatCount', formatCount)
    Vue.filter('price', price)
    Vue.filter('lowerCase', lowerCase)
    Vue.filter('startCase', startCase)
  }
}
