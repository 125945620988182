<template>
  <ItemsApiGrid
    :get-items="getProjects"
    :limit="limit"
    :offset="offset"
    #default="{items}"
  >
    <b-list-group>
      <b-list-group-item
        v-for="item in items"
        :key="item.token"
        :to="`/projects/${item.project.id}`"
      >
        <span class="text-muted small mr-3">{{item.project.id}}</span>
        <span>{{item.project.name}}</span>
        <!-- <span v-if="item.latest_order" class="ml-3 small">✓ Ordered</span> -->
      </b-list-group-item>
    </b-list-group>
  </ItemsApiGrid>
</template>

<script>
import { http2 } from '@/services/http.js'
import { APP_LIMIT_DEFAULT } from '@/services/constants.js'

export default {
  name: 'ProjectsWidget',
  props: {
    query: Object,
  },
  computed: {
    limit() {
      return Number(this.query?.limit ?? APP_LIMIT_DEFAULT)
    },
    offset() {
      return Number(this.query?.offset ?? 0)
    },
  },
  methods: {
    getProjects({ params: { offset, limit, ...params } = {} } = {}) {
      window.scrollTo(0, 0)
      const page = Math.floor(offset / limit) + 1 || 1
      return http2.get(`/api/v2/projects/`, {params: {...this.query, ...params, page, page_size: limit}})
    },
  },
}
</script>

