import { render, staticRenderFns } from "./noun_Shopping Cart_2370_000000.svg?vue&type=template&id=9a9661c8&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports