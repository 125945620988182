<template>
  <div class="pop-related">
    <a class="pop-related-image position-relative"
      v-for="image in related"
      :key="image.id"
      :href='`/search?q=${image.id}#${image.id}`'
      :class="{active: value.id === image.id}"
      @click.exact.prevent="$emit('input', image)"
    >
      <b-overlay :show="Boolean(image._processing)" no-wrap>
        <template #overlay><span></span></template>
      </b-overlay>
      <div v-if="isHidden(image)" class="info-important m-2">
        <span class="badge badge-secondary">Unpublished</span>
      </div>
      <img class="related-image"
        :class="{faded: isHidden(image)}"
        :src="getSrc(image, 'thumb')"
        :alt="image.caption || image.title"
      >
    </a>
    <div class="pop-related-image centerer">
      <router-link
        :to="{name: 'search', query: {q: query}}"
      >
        Show more...
      </router-link>
    </div>
  </div>
</template>

<script>
import { memoize } from 'lodash'

import { singleAdapter } from '@/services/searchAdapters.js'
import { randomWithSeed } from '@/services/randomGenerators.js'
import { getSrc } from '@/services/media.js'

export default {
  name: 'MediaRelated',
  props: {
    item: Object,
    value: Object,
    q: String,
    showStatus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      related: [],
      query: undefined,
    }
  },
  watch: {
    item: {
      handler: 'onChange',
      immediate: true,
    }
  },
  methods: {
    getSrc,
    onChange(item) {
      this.related = []
      this.setRelated(item)
    },
    async setRelated(item) {
      if (!item.artist) { return }
      // search for related images
      this.query = getQuery(this.item, this.q)
      let images = await getImages(item, this.query)
      if (images.filter(i => i.id !== item.id).length === 0) {
        // if no results, try again with alternate query
        this.query = getQuery(this.item, ' ')
        images = await getImages(item, this.query)
      }
      this.related = [
        item,
        ...images.filter(i => i.id !== item.id)
      ].slice(0,5)
    },
    isHidden(item) {
      return this.showStatus && !item?.published
    },
  },
}

const singleAdapterCached = memoize(singleAdapter.get, i => JSON.stringify(i))
setInterval(() => singleAdapterCached.cache.clear(), 60 * 60 * 1000) // 1 hour

async function getImages(item, query) {
  const response = await singleAdapterCached({
    q: query,
    api: isGetty(item) ? 'getty' : 'internal',
    limit: 5,
  })
  return response.images
}
function getQuery(item, phrase) {
  phrase = (isGetty(item) && !phrase) ? 'macro nature' : clean(phrase)
  const artist = `"${clean(item.artist)}"`
  let result
  if (phrase.toLowerCase().includes(item.artist.toLowerCase())) {
    result = `${artist} "${getRandom(item.keywords, item.id)}"`
  } else {
    result = `${artist} ${phrase}`
  }
  return result.trim()
}
function getRandom(arr, seed) {
  if (!arr) { return '' }
  const index = Math.floor(randomWithSeed(seed) * arr.length)
  return arr[index]
}
export function isGetty(item) {
  return ['GET', 'GETRM'].includes(item?.source)
}
function clean(val) {
  return (val === undefined) ? '' : val
}
</script>

<style lang="scss" scoped>
$height: 10vw;
$margin: 0.5vw;

.pop-related {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  min-height: $height + ($margin * 2);
}
.pop-related-image {
  margin: $margin;
  text-align: center;
}
.related-image {
  max-height: 10vw;
}
.active {
  outline: 2px solid $gray-400;
  outline-offset: 1px;
}
.centerer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.faded {
  opacity: 0.3;
}
.info-important {
  position: absolute;
  z-index: 2;
  line-height: 1;
}
</style>
