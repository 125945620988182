<template>
  <div class="keywords">
    <router-link class="keyword"
      v-for="item in items"
      :key="item.id"
      :to="{query: {q: item.label}}"
    >
      <img class="keyword-image" :src="item.thumb" alt="">
      {{item.label}}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'RelatedKeywords',
  props: {
    items: Array,
  },
}
</script>

<style lang="scss" scoped>
  .keywords {
    overflow: auto;
    white-space: nowrap;
  }
  .keyword {
    display: inline-block;
    margin-right: 20px;
    padding: 5px;
    border: 1px solid transparent;
    text-decoration: none;
    color: inherit;

    &:focus,
    &:hover {
      border-color: #ccc;
    }
  }
  .keyword-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
</style>
