/**
 * Add a value to a numerically sorted list
 * e.g. insertSortedNumeric([1, 10, 100], 50) → [1, 10, 50, 100]
 */

export function insertSortedNumeric(items, item) {
  item = Number(item)
  if (!item || isNaN(item) || items.includes(item)) {
    return items
  }
  return items
    .concat(item)
    .sort((a,b) => a - b)
}
