<template>
  <div id="app">
    <router-view></router-view>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
import '@/styles/_vendors/bootstraps.scss'
import '@/styles/_vendors/vue-multiselect-bootstrap.scss'
import '@/styles/app.scss'

export default {
  name: 'App',
  created() {
    // progress bar for lazy-loaded chunks
    this.$router.beforeEach((to, from, next) => {
      this.$SmartProgress.start({delay: 100})
      next()
    })
    this.$router.afterEach(() => {
      this.$SmartProgress.finish()
    })
    this.$router.onError(() => {
      this.$SmartProgress.fail()
    })
  },
}
</script>
