<template>
  <div>
    <OptionsForm
      ref="formUpload"
      @submit="onSubmit"
      v-model="uploadData"
      :fields="activeFields"
      :errors.sync="errors"
      :omitFields="(val, key) => val.read_only || ['creator', 'creator_uuid', 'published'].includes(key)"
    ><span/></OptionsForm>
    <b-form-group
      class="mb-0"
      label-cols-sm="4"
      label-cols-lg="3"
    >
      <button
        class="btn btn-outline-primary"
        v-b-modal.modal-add
      >+ Add new {{entityType}}</button>
    </b-form-group>
    <b-modal
      id="modal-add"
      :title="`Add new ${entityType}`"
      ref="modalAdd"
      @ok.prevent="onAdd"
    >
      <OptionsFormApi
        ref="formAdd"
        :api="entityType"
        :omitFields="field => field.read_only"
      ><span/></OptionsFormApi>
    </b-modal>
  </div>
</template>

<script>
import { omit, pickBy } from 'lodash'
import { mapState } from 'vuex'

import OptionsForm from '@/components/OptionsForm.vue'
import OptionsFormApi from '@/components/OptionsFormApi.vue'
import { http } from '@/services/http.js'

export default {
  name: 'UploadForm',
  components: {
    OptionsForm,
    OptionsFormApi,
  },
  props: {
    mode: String,
    beforeSubmit: {
      type: Function,
      default() {
        return Promise.resolve()
      },
    },
  },
  data() {
    return {
      formFields: null,
      uploadData: {
      },
      errors: {},
    }
  },
  computed: {
    ...mapState('user', [
      'user',
    ]),
    activeFields() {
      // make artist/publisher field required
      const fields = {
        ...this.formFields,
        [this.entityType]: {
          ...this.formFields?.[this.entityType],
          required: true,
        },
      }
      // omit the other field
      const omitProp = this.mode === 'artist' ? 'publisher' : 'artist'
      return omit(fields, [omitProp])
    },
    entityType() {
      return this.mode === 'artist' ? 'artist' : 'publisher'
    },
  },
  watch: {
    mode(val) {
      const removeProp = val === 'artist' ? 'publisher' : 'artist'
      this.uploadData = omit(this.uploadData, [removeProp])
      this.errors = {}
    },
    uploadData: {
      handler(val) {
        this.$emit('update:uploadData', val)
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.fetchFormFields()
      .catch(err => {
        this.$store.dispatch('errors/setError', err)
        throw err
      })
    this.$set(this.uploadData, 'creator_uuid', this.user.uuid)
  },
  methods: {
    async onSubmit() {
      if (!this.$refs.formUpload.clientValidate()) {
        throw new Error('Form failed client-side validation')
      }
      await this.beforeSubmit()
      const id = this.uploadData.id
      const response = await http[id ? 'put' : 'post'](
        '/api/upload/' + (id ? `${id}/` : ''),
        this.uploadData
      ).catch(err => {
        this.errors = err.response.data
        let message
        if (err.response && err.response.status === 400) {
          message = `Invalid fields: ${Object.keys(err.response.data).join(', ')}`
        } else {
          message = `${err}`
        }
        this.$alert.simple(message, {variant: 'danger'})
        throw err
      })
      this.uploadData = response.data
      this.$emit('success', response.data)
      return response.data
    },
    async onAdd() {
      const newItem = await this.$refs.formAdd.onSubmit()
      this.$set(this.uploadData, this.mode, newItem.id)
      this.$nextTick(() => {
        this.$refs.modalAdd.hide()
      })
    },
    async fetchFormFields() {
      this.formFields = await this.getFormFields()
    },
    getFormFields() {
      return http.options(
        '/api/upload/'
      ).then(response => {
        return response.data.actions.POST
      })
      .then(modifyArtistField)
    },
  }
}

function modifyArtistField(fields) {
  return {
    ...fields,
    artist: {
      ...fields.artist,
      props: {
        trackBy: 'id',
        customLabel: customLabelArtist,
      },
    },
    publisher: {
      ...fields.publisher,
      props: { trackBy: 'id' },
    },
  }
}

export function customLabelArtist(option, label) {
  // Add info to labels e.g. "John Doe (Portal)"
  const result = customLabelDefault(option, label)
  let info = {
    Portal: option?.gaa_artist,
    Inactive: option?.active === false,
  }
  info = Object.keys(pickBy(info))
  info = info.length ? ` (${info.join(', ')})` : ''
  return `${result}${info}`
}

// from https://github.com/shentao/vue-multiselect/blob/2.1.6/src/multiselectMixin.js#L199-L202
function isEmpty (opt) {
  if (opt === 0) return false
  if (Array.isArray(opt) && opt.length === 0) return true
  return !opt
}
function customLabelDefault(option, label) {
  if (isEmpty(option)) return ''
  return label ? option[label] : option
}
</script>
