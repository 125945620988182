<template>
  <div
    :class="{dimmed: disabled || busy}"
    v-b-tooltip.hover
    :title="(compact) ? label : undefined"
    :key="(published) ? 'shown' : 'hidden'"
  >
    <b-spinner small
      v-if="busy"
    />
    <IconCircleSlash
      class="fill-color"
      v-else-if="published"
      key="shown"
    />
    <IconEye
      class="fill-color"
      v-else
      key="hidden"
    />
    <span v-if="!compact" class="ml-2">{{label}}</span>
  </div>
</template>

<script>
import { http } from '@/services/http.js'
import { isGetty } from '@/components/MediaRelated.vue'

import IconEye from '@primer/octicons/build/svg/eye-16.svg'
import IconCircleSlash from '@primer/octicons/build/svg/circle-slash-16.svg'

export default {
  name: 'QuickMenuHide',
  components: {
    IconEye,
    IconCircleSlash,
  },
  props: {
    item: Object,
    compact: Boolean,
  },
  data() {
    return {
      busy: false,
    }
  },
  computed: {
    published() {
      return this.item?.published
    },
    disabled() {
      return isGetty(this.item)
    },
    label() {
      if (isGetty(this.item)) {
        return `Getty results cannot be ${this.published ? 'un' : ''}published.`
      }
      return (this.published) ? 'Unpublish' : 'Publish'
    },
  },
  methods: {
    async toggle() {
      if (this.disabled) { return }
      if (this.busy) { return }
      try {
        this.busy = true
        const publish = !this.published
        await http.patch(`/content/edit/${this.item.id}/`, {
          publish,
        })
        this.item.published = publish // mutate parent state
        const status = (publish) ? 'published' : 'unpublished'
        const variant = (publish) ? 'success' : 'secondary'
        this.$bvToast.toast(`${this.item.id} ${status}`, {variant})
      }
      catch (err) {
        this.$alert.simple(`${err}\nPlease try again. Contact customer support if this persists.`, {variant: 'danger'})
      }
      finally {
        this.busy = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.dimmed {
  opacity: $btn-disabled-opacity;
}
</style>
