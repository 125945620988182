import { memoize } from 'lodash'
import { http } from '@/services/http.js'

const state = {
  collections: null,
}

const dataFns = {
  default: (type) => http.get(`/api/${type}/`, {params: {limit: 1000, ordering: '-modified'}})
    .then(response => response.data.results),
}
function getData(type) {
  const fn = dataFns[type] || dataFns.default
  return fn(type)
}
async function uncachedFetch({ commit }, type) {
  const data = await getData(type)
  commit('setCache', { [type]: data })
  return data
}
const cachedFetch = memoize(uncachedFetch , (context, type) => type)

const actions = {
  fetch: cachedFetch,
  refetch(context, type) {
    cachedFetch.cache.delete(type)
    return cachedFetch(context, type)
  },
  delete({ commit }, type) {
    cachedFetch.cache.delete(type)
    commit('setCache', { [type]: null })
  },
  clear({ commit }) {
    cachedFetch.cache.clear()
    commit('clear')
  },
}

const mutations = {
  setCache(state, data) {
    Object.keys(data).forEach(key => {
      state[key] = data[key]
    })
  },
  clear(state) {
    Object.keys(state).forEach(key => {
      state[key] = null
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}


