<template>
  <div class="pagination-bar form-inline">
    <slot>
      <div>{{total | formatCount}}</div>
    </slot>
    <PaginationLimit
      class="ml-3"
      :limit="limit"
      :options="limitOptions"
    />
    <PaginationNav
      class="ml-auto"
      :total="total"
      :limit="limit"
      :compact="compact"
    />
  </div>
</template>

<script>
import PaginationLimit from './PaginationLimit.vue'
import PaginationNav from './PaginationNav.vue'

export default {
  name: 'Pagination',
  components: {
    PaginationLimit,
    PaginationNav,
  },
  props: {
    limit: null,
    limitOptions: null,
    compact: null,
    total: null,
  },
}
</script>
