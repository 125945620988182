<script>
import OptionsFormFields from '@/components/OptionsFormFields.vue'
import OptionsFormWide from '@/components/OptionsFormWide.vue'

export default {
  name: 'OptionsFormFieldsWide',
  extends: OptionsFormFields,
  components: {
    OptionsForm: OptionsFormWide,
  },
}
</script>
