// SmartProgress is a wrapper for vue-progressbar.
// - Add support for start delay
// - Fix unintentional hiding issue

let timeout = null

function getSmartProgress($p) {
  return {
    start({delay} = {}) {
      clearTimeout(timeout)
      return delay ? timeout = setTimeout(() => $p.start(), delay)
                   : $p.start()
    },
    // The finish and fail methods use a timeout so they can be cancelled when
    // start is called immediately afterwards. This is necessary because
    // vue-progressbar's finish and fail methods use a timeout to hide the
    // progress bar, which hides the just-started progress bar unintentionally.
    finish() {
      clearTimeout(timeout)
      timeout = setTimeout(() => $p.finish())
    },
    fail() {
      clearTimeout(timeout)
      timeout = setTimeout(() => $p.fail())
    },
  }
}

export default {
  install(Vue) {
    const { $Progress } = Vue.prototype
    if (!$Progress) { throw new Error('Missing required plugin "vue-progressbar"')}
    Vue.prototype.$SmartProgress = getSmartProgress($Progress)
  }
}
