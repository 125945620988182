<template>
  <div
    class="fav-button"
    @click.prevent.stop="toggleFav()"
    v-b-tooltip.hover
    :title="(internal) ? 'Un-favorite' : 'Favorite'"
    :key="(internal) ? 'fav-active' : 'fav-inactive'"
  >
    <IconHeart
      class="icon"
      :class="{active: internal}"
    />
  </div>
</template>

<script>
import { http } from '@/services/http.js'

import IconHeart from '@primer/octicons/build/svg/heart-fill-16.svg'

export default {
  name: 'FavButton',
  components: {
    IconHeart,
  },
  props: {
    id: String,
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internal: this.value,
    }
  },
  watch: {
    value(newValue) {
      this.internal = newValue
    },
  },
  methods: {
    async toggleFav() {
      try {
        const val = !this.internal
        await http.patch(`/content/edit/${this.id}/`, {
          favorite: val,
        })
        if (val) {
          this.$bvToast.toast('Favorited', {variant: 'success', noCloseButton: true, solid: true})
        } else {
          this.$bvToast.toast('Un-favorited', {variant: 'secondary', noCloseButton: true, solid: true})
        }
        this.internal = val
      }
      catch (err) {
        this.$alert.simple(`${err}\nPlease try again. Contact customer support if this persists.`, {variant: 'danger'})
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .fav-button {
    cursor: pointer;
  }
  .icon {
    overflow: visible;
    transform: scale(1.1);
    stroke-width: 1.25;
    fill: transparent;
    stroke: white;
    &.active {
      fill: white;
    }
  }
</style>
