<template>
  <div>
    <ErrorDisplay :value="error"/>

    <div v-if="items && total > limit_" class="d-flex align-items-center mb-3">
      <div class="ml-auto"></div>
      <b-pagination
        class="mb-0"
        v-model="page"
        :total-rows="total"
        :per-page="limit_"
      />
    </div>

    <slot
      v-if="!error"
      :items="items"
      :total="total"
      :busy="loading"
    >
      <pre>{{items}}</pre>
    </slot>

    <div v-if="items && total > limit_" class="d-flex align-items-center mt-3">
      <div class="ml-auto"></div>
      <b-pagination
        class="mb-0"
        v-model="page"
        :total-rows="total"
        :per-page="limit_"
      />
    </div>
  </div>
</template>

<script>
import { pickBy } from 'lodash'

export default {
  name: 'ItemsApi',
  props: {
    getItems: {
      type: Function,
      required: true,
    },
    limit: Number,
    offset: Number,
  },
  data() {
    return {
      loading: false,
      error: null,
      items: null,
      total: null,
      limit_: this.limit,
      offset_: this.offset,
    }
  },
  computed: {
    page: {
      get() { return (Math.floor(this.offset_ / this.limit_) + 1) || 1},
      set(val) { this.offset_ = (val - 1) * this.limit_ },
    },
  },
  watch: {
    getItems: 'fetchItems',
    limit(val) {
      this.limit_ = val
      this.fetchItems()
    },
    offset(val) {
      this.offset_ = val
      this.fetchItems()
    },
    page: 'fetchItems',
  },
  created() {
    this.fetchItems()
  },
  methods: {
    async fetchItems() {
      this.loading = true
      let params = {limit: this.limit_, offset: this.offset_}
      params = pickBy(params, i => i !== undefined)
      const data = await this.getItems({params})
        .then(response => response.data)
        .catch(err => {
          this.error = err
          throw err
        })
        .finally(() => {
          this.loading = false
        })
        if (Object.hasOwnProperty.call(data, 'results')) {
          // paginated (DRF)
          this.items = data.results
          this.total = data.count
        } else if (Object.hasOwnProperty.call(data, 'count')) {
          // paginated
          this.items = data.data
          this.total = data.count
          this.limit_ = data.limit
          this.offset_ = data.offset
        } else {
          this.items = data
        }
    },
  },
}
</script>
