<template>
  <div class="centered">Signing out...</div>
</template>

<script>
// to redirect to another page after login, set redirect prop to:
// this.$router.resolve({name: 'accounts-login', query: {redirect: 'another page'}}).href

export default {
  name: 'AccountLogout',
  props: {
    redirect: String,
  },
  created() {
    this.$SmartProgress.start()
    this.$store.dispatch('user/logout')
      .then(() => {
        this.$SmartProgress.finish()
        this.$router.replace(this.redirect || {name: 'accounts-login'})
      })
      .catch(err => {
        this.$SmartProgress.fail()
        this.$alert.persistent(`${err}\nPlease try reloading the page. Contact customer support if this persists.`, {variant: 'danger'})
        throw err
      })
  },
  methods: {},
}
</script>
