<template>
  <header>
  <div class="bg-dark d-print-none" :style="{height: navHeight}"></div><!-- placeholder for fixed navbar -->
  <b-navbar toggleable="sm" type="dark" variant="dark" ref="main" class="lg-text fixed-top">
    <div class="container-fluid site-width">
    <b-navbar-brand :[homeAttr]="homeUrl" class="p-sm-0" title="Home">
      <GaaLogo class="logo"/>
    </b-navbar-brand>

    <b-navbar-toggle target="nav_collapse" />

    <b-navbar-nav class="flex-fill py-1 search-nav mx-md-3">
      <SiteSearch/>
    </b-navbar-nav>

    <b-collapse is-nav id="nav_collapse" class="flex-grow-0 align-items-stretch">
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto text-nowrap align-items-sm-center ruled">
        <b-nav-item-dropdown menu-class="dynamic-alignment">
          <template #button-content>
            <span title="Help">
              <IconQuestion title="Help" viewBox="0 0 24 24" class="icon d-lg-none mr-2 mr-sm-0"/>
            </span>
            <span class="align-middle d-sm-none d-lg-inline">Help</span>
          </template>
          <b-dropdown-item href="tel:800-314-0434" link-class="dropdown-primary">
            Call <strong>800-314-0434</strong><br>
            <small>to speak with a Designer</small>
          </b-dropdown-item>
          <b-dropdown-item @click="chat" link-class="dropdown-primary"><strong>Live Chat</strong></b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown v-if="user" menu-class="dynamic-alignment">
          <template slot="button-content">
            <span title="My Account">
              <IconPerson viewBox="0 0 24 24" class="icon d-lg-none bg-transparent mr-2 mr-sm-0"/>
            </span>
            <div class="d-inline-block d-sm-none d-lg-inline-block align-middle md-text mw-150">
              <b>My Account</b>
              <div class="text-truncate small">
                {{user.name || user.email}}
              </div>
            </div>
          </template>
          <b-dropdown-item
            v-if="artistProfile"
            :to="{name: valid ? 'artist-profile' : 'artist'}"
          >
            <div class="d-flex">
              <b-avatar
                :src="artistProfile.photo"
              />
              <div class="ml-2">
                <div>{{artistProfile.full_name}}</div>
                <div class="small" style="opacity: 0.5;">View Profile</div>
              </div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="profile"
            :href="`${API_URL}dashboard`"
          >
            Dashboard
          </b-dropdown-item>
          <b-dropdown-item
            v-if="profile"
            :href="`${API_URL}projects`"
          >Projects</b-dropdown-item>
          <b-dropdown-item to="/orders">
            Orders
          </b-dropdown-item>
          <b-dropdown-item v-if="artistProfile" to="/artist">
            Artist Portal
          </b-dropdown-item>
          <b-dropdown-divider/>
          <b-dropdown-item :to="{name: 'accounts-logout'}">
            Sign out
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item v-else :to="{name: 'accounts-login', query: {redirect: $route.query.redirect || $route.fullPath}}">
          Sign In
        </b-nav-item>

        <b-nav-item
          v-b-tooltip
          :title="cart && cart.quantity ? `${formatCount(cart.quantity, 'item')}` : (profile ? undefined : 'Cart is empty')"
          to="/cart"
        >
          <SiteCart :quantity="cart && cart.quantity"/>
          <div v-if="profile" class="d-inline-block d-sm-none d-lg-inline-block align-middle ml-2 md-text mw-150">
            <b>Project</b>
            <div class="text-truncate small">{{cart && cart.project ? cart.project.name : '—'}}</div>
          </div>
          <span v-else class="d-sm-none d-lg-inline ml-2">Cart</span>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
    </div>
  </b-navbar>
  <b-navbar v-if="isStaff" type="dark" variant="dark" class="overflow-auto text-nowrap pt-1 px-0 mt-n1">
    <SiteMenuStaff class="flex-fill site-width mx-auto px-3 staff-menu"/>
  </b-navbar>
  <div class="d-none d-print-block container-fluid">
    <GaaLogo class="logo"/>
  </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'

import SiteCart from '@/components/SiteCart.vue'
import SiteMenuStaff from '@/components/SiteMenuStaff.vue'
import SiteSearch from '@/components/SiteSearch.vue'
import { HOME_URL, API_URL } from '@/services/constants.js'
import { formatCount } from '@/utils/count.js'
import IconQuestion from '@primer/octicons/build/svg/question-24.svg'
import IconPerson from '@primer/octicons/build/svg/person-24.svg'
import GaaLogo from '@/images/gaa-logo.svg'

export default {
  components: {
    IconQuestion,
    IconPerson,
    GaaLogo,
    SiteCart,
    SiteMenuStaff,
    SiteSearch,
  },
  data() {
    return {
      API_URL,
      homeUrl: HOME_URL,
      homeAttr: HOME_URL.match(/^http/) ? 'href' : 'to',
    }
  },
  computed: {
    ...mapState('user', [
      'user',
      'profile',
      'artistProfile',
    ]),
    isStaff() { return this.$store.getters['user/isStaff'] },
    ...mapState('cart', {
      cart: 'cart',
    }),
    valid() {
      return this.artistProfile && this.artistProfile.application_stage === 'payment'
    },
    navHeight() {
      return this.$store.state.appearance.navHeight
    },
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    formatCount,
    chat() {
      /* global drift */
      drift.on('ready',function(api){
        api.openChat()
      })
    },
    onResize() {
      this.$store.commit('appearance/setNavHeight', this.$refs.main.$el.offsetHeight + 'px')
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar.navbar-expand-sm {
  @include media-breakpoint-up(sm) {
    padding-left: 0;
    padding-right: 0;
  }
}

.lg-text {
  font-size: 1.4rem;
}

.md-text {
  font-size: 1rem;
  line-height: 1.2;
}

.mw-150 {
  max-width: 150px;
}

.search-nav {
  width: 100%;
  @include media-breakpoint-up(sm) {
    width: 0; // necessary for IE 11
  }
}

.logo {
  display: block;
  fill: currentColor;
  height: 36px;
  margin: 3px 0;
  max-width: 60px; // necessary for IE 11
  @include media-breakpoint-up(sm) {
    height: 54px;
    margin: 5px 0;
    max-width: 90px; // necessary for IE 11
  }
}

/deep/ .icon,
.icon {
  width: 1.2em;
  height: 1.2em;
}

/deep/ .form-control {
  &:focus,
  &.focus {
    // box-shadow from btn-variant bootstrap mixin, shows up better on dark background
    box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($primary), $primary, 15%), .5);
  }
}

.scroll-section {
  @include media-breakpoint-up(sm) {
    white-space: nowrap;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
    &::after {
      position: sticky;
      top: 0;
      right: 0;
      min-width: 40px;
      height: 100%;
      pointer-events: none;
      display: block;
      content: '';
      background-image: linear-gradient(to right, rgba($dark,0), $dark);
    }
  }
}

.ruled {
  /deep/ .dropdown-toggle::after {
    vertical-align: middle;
    border-top-color: $primary;
  }
  @include media-breakpoint-up(lg) {
    > li:not(:last-child) {
      &::after {
        content: '';
        height: 80%;
        border-left: 1px solid rgba(white, 0.2);
        margin-left: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: middle;
      }
      > /deep/ .nav-link {
        display: inline-block;
      }
    }
  }
}

// conditional version of .dropdown-menu-right
/deep/ .dynamic-alignment {
  @include media-breakpoint-between(sm, md) {
    right: 0;
    left: auto;
  }
}

.staff-menu /deep/ > li + li {
  @include media-breakpoint-up(sm) {
    margin-left: 20px;
  }
}

.dropdown-primary {
  strong {
    color: $link-color;
  }
  &:hover,
  &:focus {
    strong {
      color: $link-hover-color;
    }
  }
  &.active,
  &:active {
    strong {
      color: $dropdown-link-active-color;
    }
  }
  &.disabled,
  &:disabled {
    strong {
      color: $dropdown-link-disabled-color;
    }
  }
}
</style>
