<template>
  <a v-if="url" :href="url">
    {{text}}
  </a>
  <span v-else>{{text}}</span>
</template>

<script>
import { startCase } from 'lodash'

import { API_URL } from '@/services/constants.js'

export default {
  name: 'CartId',
  props: {
    noun: String,
    cart: Object,
  },
  computed: {
    text() {
      return `${startCase(this.noun)} ID: ${this.cart?.project?.id}`
    },
    url() {
      return this.$store.state.user.profile && `${API_URL}projects/${this.cart?.project?.id}`
    },
  },
}
</script>
