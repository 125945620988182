import { http2 } from '@/services/http.js'
import { CART_LIMIT_DEFAULT } from '@/services/constants.js'

// initial state
const state = {
  cart: null,
  items: null,
  guest: null,
}

// getters
const getters = {
  token(state) {
    return state.cart?.token
  },
}

// actions
const actions = {
  createCart({ commit }) {
    return http2.post('/api/v2/cart/')
      .then(response => commit('setCart', response.data))
  },
  fetchCart({ state, commit }) {
    if (!state.cart) { return }
    return http2.get(`/api/v2/cart/${state.cart.token}/`)
      .then(response => commit('setCart', response.data))
  },
  fetchItems({ state, commit }) {
    if (!state.cart) { return }
    return http2.get(`/api/v2/cart/${state.cart.token}/items/`, {params: {page_size: CART_LIMIT_DEFAULT}})
      .then(response => commit('setItems', response.data))
  },
  fetchCartFull({ dispatch }) {
    return Promise.all([
      dispatch('fetchCart'),
      dispatch('fetchItems'),
    ])
  },
  fetchCartStart({ state, dispatch, rootState }) {
    // usage note: when fetching both the user profile and the cart, do it sequentially,
    // in that order, for reliable anon cart migration and current_project handling

    // assign ownership of guest carts to the signed in user
    // e.g. when someone signs in on the checkout page
    if (rootState.user?.profile && state.cart && state.guest) {
      return dispatch('checkMigrate')
        .then(() => Promise.all([
          dispatch('fetchItems'),
          // update the current_project after migration
          dispatch('user/fetchProfile', null, {root: true}),
        ]))
    }
    // if current_project does not match cart, fetch the current_project cart
    const id = rootState.user?.profile?.current_project?.id
    if (id && id !== state.cart?.project?.id) {
      return dispatch('fetchCurrentCart', id)
    }
    // if we have  cart, update it, if no cart, check for carts
    return (state.cart) ? dispatch('fetchCartFull')
                        : dispatch('fetchRecentCart')
  },
  fetchCurrentCart({ commit, dispatch }, id) {
    if (!id) { return }
    commit('setCart', {token: id})
    commit('setItems', null)
    return dispatch('fetchCartFull')
  },
  fetchRecentCart({ commit, dispatch }) {
    return http2.get(`/api/v2/cart/`, {limit: 1})
      .then(response => {
        const item = response.data.results[0]
        if (!item || item.latest_order) { return }
        commit('setCart', item)
        return dispatch('fetchItems')
      })
  },
  checkMigrate({ state, commit }) {
    if (state.cart && state.guest) {
      return http2.post(`/api/v2/cart/${state.cart.token}/migrate-cart/`)
        .then(response => {
          commit('setCart', response.data)
          commit('setGuest', null)
          return response
        })
    }
  },
  async reset({ commit }) {
    commit('clear')
  },
}

// mutations
const mutations = {
  setCart(state, data) {
    state.cart = data
  },
  setItems(state, data) {
    state.items = data
  },
  setGuest(state, data) {
    state.guest = data
  },
  clear(state) {
    Object.keys(state).forEach(key => {
      state[key] = null
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
