<template>
  <div class="position-relative">
    <b-form-input
      :list="uid"
      @input="onInput"
      v-model="search"
      v-bind="$attrs"
      v-on="$listeners"
      :class="inputClass"
      autocomplete="off"
    />
    <b-form-datalist
      :id="uid"
      :options="data && data.results"
      :value-field="valueField"
      :text-field="textField"
    >
      <option v-if="data && data.results && data.count > data.results.length" value="…">
        and {{(data.count - data.results.length).toLocaleString()}} more
      </option>
    </b-form-datalist>
  </div>
</template>

<script>
import { uniqueId, debounce } from 'lodash'

export default {
  name: 'TypeaheadDynamic',
  inheritAttrs: false,
  props: {
    getItems: Function,
    valueField: String,
    textField: String,
    inputClass: null,
  },
  data() {
    return {
      uid: uniqueId('typeahead-'),
      data: null,
      search: '',
    }
  },
  created() {
    this.debouncedFetchItems = debounce(this.fetchItems, 300)
    this.fetchItems()
  },
  methods: {
    getValue(item) {
      return this.valueField ? item[this.valueField] : item
    },
    onInput(val) {
      if (val === '…') {
        setTimeout(() => {
          this.search = ''
        }, 0)
        return
      }
      const item = this.data?.results?.find(i => String(this.getValue(i)) === val)
      if (item) {
        this.$emit('select', item)
      } else {
        this.debouncedFetchItems(val)
      }
    },
    async fetchItems(val) {
      this.data = await this.getItems(val)
    },
  },
}
</script>
