<template>
  <b-button
    v-bind="$attrs"
    v-on="$listeners"
    class="position-relative"
  >
    <LoadingButtonContent :busy="busy"><slot/></LoadingButtonContent>
  </b-button>
</template>

<script>
import LoadingButtonContent from '@/components/LoadingButtonContent.vue'

export default {
  name: 'LoadingButton',
  components: {
    LoadingButtonContent,
  },
  inheritAttrs: false,
  props: {
    busy: Boolean,
  },
}
</script>
