<script>
import { mapValues } from 'lodash'

import OptionsForm, { transformFields } from '@/components/OptionsForm.vue'

export default {
  name: 'OptionsFormWide',
  extends: OptionsForm,
  props: {
    labelColsSm: {
      type: String,
      default: '0',
    },
    labelColsLg: {
      type: String,
      default: '0',
    },
  },
  computed: {
    fieldConfigs() {
      const fields = mapValues(this.fields_, (i) => ({
        ...i,
        props: {
          ...i.props,
          placeholder: this.getLabelOriginal(i),
        },
      }))
      return transformFields(fields)
    },
  },
  methods: {
    getLabelOriginal(field) {
      return field.label + (field.required ? this.labelRequired : '')
    },
    getLabel(field) {
      return this.isBoolean(field)
        ? this.getLabelOriginal(field)
        : ''
    },
  },
}
</script>
