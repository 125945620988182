const VAlert = {
  alerts: [],
  lastAlert: -1,
  reset: function () {
    this.alerts.splice(0,this.alerts.length)
  },
  addAlert: function (options) {
    this.lastAlert++
    const alertId = this.lastAlert
    this.alerts.push(Object.assign({
      id: alertId,
      message: options.message,
      remove: () => this.removeAlert(alertId),
    }, options))
    return this.lastAlert
  },
  removeAlert: function (id) {
    const index = this.alerts.map(i => i.id).indexOf(id)
    this.alerts.splice(index, 1)
    return true
  },
  simple: function (val, options) {
    options = Object.assign({
      message: val,
      variant: 'warning',
      duration: 5,
    }, options)
    this.addAlert(options)
  },
  persistent: function (val, options) {
    options = Object.assign({
      message: val,
      variant: 'warning',
      dismissible: true,
    }, options)
    this.addAlert(options)
  },
}

export default VAlert
