<template>
  <div
    class="fill-color"
    :class="{'text-truncate': compact}"
    v-b-tooltip.hover
    :title="(compact) ? tooltip : undefined"
  >

    <IconPlus/>
    <span class="ml-2">{{label}}</span>
    <QuickModal
      ref="modal"
      title="New collection"
      centered
      :busy="busy"
      ok-variant="primary position-relative"
    >
      <OptionsFormFieldsWide
        ref="form"
        v-model="value"
        :fields="fields"
        api="collections"
        @success="$refs && $refs.modal.hide('ok')"
        @update:busy="busy = $event"
      ><span/></OptionsFormFieldsWide>
      <template #modal-ok>
        <LoadingButtonContent :busy="busy">Save</LoadingButtonContent>
      </template>
    </QuickModal>
  </div>
</template>

<script>
import IconPlus from '@primer/octicons/build/svg/plus-16.svg'

import LoadingButtonContent from '@/components/LoadingButtonContent.vue'
import OptionsFormFieldsWide from '@/components/OptionsFormFieldsWide.vue'
import QuickModal from '@/components/QuickModal.vue'

const fields = {
  name: {
    type: "string",
    required: true,
    read_only: false,
    label: "Name",
    max_length: 100,
  },
  description: {
    type: "textarea",
    required: false,
    read_only: false,
    label: "Description",
  },
}

export default {
  name: 'QuickMenuCollectionCreate',
  components: {
    IconPlus,
    LoadingButtonContent,
    OptionsFormFieldsWide,
    QuickModal,
  },
  props: {
    compact: Boolean,
  },
  data() {
    return {
      value: {},
      fields,
      label: 'New Collection',
      tooltip: 'Add to new collection',
      busy: false,
    }
  },
  computed: {},
  methods: {
    async toggle() {
      const data = await this.$refs?.modal.getValue(this.getInput)
      const {id, name} = data
      await this.$store.dispatch('cache/refetch', 'collections')
      const button = { id, is: 'QuickMenuCollection', props: { collection: id, collectionName: name } }
      const quickMenu = this.$parent.$parent
      quickMenu.go(button)
    },
    getInput() {
      //todo: fix double requests, because the modal wants to catch the hide event pre-request
      return this.$refs.form.onSubmit()
    },
  },
}
</script>
