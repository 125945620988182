import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import errors from './modules/errors'
import appearance from './modules/appearance'
import cache from './modules/cache'
import preferences from './modules/preferences'
import user from './modules/user'
import cart from './modules/cart'

Vue.use(Vuex)

// Version the vuex store by changing the persistedstate key and removing previous keys
// https://github.com/robinvdvleuten/vuex-persistedstate/issues/231#issuecomment-603324801
// ['vuex-gapco-v0'].forEach(i => window.localStorage.removeItem(i))

export default new Vuex.Store({
  modules: {
    errors,
    appearance,
    cache,
    preferences,
    user,
    cart,
  },
  plugins: [
    createPersistedState({
      key: process.env.VUE_APP_PERSISTEDSTATE_KEY,
      storage: window.localStorage,
      paths: [
        'preferences',
        'user',
        'cart',
      ],
    })
  ],
})
