<template>
  <div class="position-absolute d-flex align-items-center controls-container p-1 m-n2">
    <label title="Close" class="pointer p-2 m-0">
      <button type="button"
        class="btn btn-translucent p-1 rounded-pill"
        @click="$emit('close', $event)"
      >
        <IconX height="1em" width="1em" viewBox="0 0 16 16" class="d-block"/>
      </button>
    </label>

    <div class="ml-auto"></div>
    <label title="Previous" class="pointer p-2 m-0">
      <button type="button"
        class="btn btn-translucent p-1 rounded-pill"
        @click="$emit('previous', $event)"
      >
        <IconChevronLeft height="1em" width="1em" viewBox="0 0 16 16" class="d-block"/>
      </button>
    </label>
    <label title="Next" class="pointer p-2 m-0">
      <button type="button"
        class="btn btn-translucent p-1 rounded-pill"
        @click="$emit('next', $event)"
      >
        <IconChevronRight height="1em" width="1em" viewBox="0 0 16 16" class="d-block"/>
      </button>
    </label>
  </div>
</template>

<script>
import IconX from '@primer/octicons/build/svg/x-16.svg'
import IconChevronLeft from '@primer/octicons/build/svg/chevron-left-16.svg'
import IconChevronRight from '@primer/octicons/build/svg/chevron-right-16.svg'

export default {
  name: 'MediaQuickControls',
  components: {
    IconX,
    IconChevronLeft,
    IconChevronRight,
  },
}
</script>

<style lang="scss" scoped>
.controls-container {
  $half: ($grid-gutter-width / 2);
  top: 0;
  left: $half;
  right: $half;
  pointer-events: none;
  > * {
    pointer-events: auto;
  }
}
.btn-translucent {
  font-size: 1.25rem;
}
</style>
