<template>
  <div class="d-flex flex-column justify-content-center align-items-center fixed-top fixed-bottom pt-5">
    <div class="overflow-auto p-3">
      <div
        class="d-flex flex-column align-items-center"
        v-for="message in messagesNormalized"
        :key="message.id"
      >
        <h1>{{message.title}}</h1>
        <p class="text-break">{{message.description}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { objectId } from '@/utils/objectId.js'

export default {
  name: 'MessageOverlay',
  props: {
    messages: Array,
  },
  computed: {
    messagesNormalized() {
      return this.messages.map(i => {
        return {
          id: getId(i),
          title: i.title,
          description: (i.title || i.description) ? i.description : i,
        }
      })
    }
  },
}

function getId(val) {
  try {
    return objectId(val)
  } catch (err) {
    return val
  }
}
</script>
