import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueProgressBar from 'vue-progressbar'

import router from './router.js'
import store from './store'

import App from './App.vue'
import Filters from './Filters.js'
import Globals from './Globals.js'
import VAlert from './features/VAlert/plugin.js'
import SmartProgress from './features/SmartProgress'
import { UI_URL } from './services/constants.js'

Vue.config.productionTip = false
Vue.use(BootstrapVue, {
  BModal: { centered: true },
  BPagination: { firstNumber: true, lastNumber: true },
  BToast: { toaster: 'b-toaster-bottom-right' }
});
Vue.use(Filters);
Vue.use(Globals)
Vue.use(VueProgressBar, {
  autoFinish: false,
})
Vue.use(SmartProgress)
Vue.use(VAlert)

new Vue({
  router,
  store,
  beforeCreate() {
    if (router.currentRoute.name !== 'accounts-logout') {
      // check if logged in
      const checkingAuth = store.dispatch('user/checkAuth')
      // check for cart, must happen after after checking auth, see cart.js for why
      const checkingCart = checkingAuth.catch(() => {}).then(checkCart)
      store.commit('appearance/setCheckingCart', checkingCart.finally(() => {
        store.commit('appearance/setCheckingCart', null)
      }))
    }
  },
  render: h => h(App)
}).$mount('#app')

async function checkCart() {
  // if we have a cart, check for updates, if no cart, check for carts
  return store.dispatch('cart/fetchCartStart')
}

// send cart id to drift
store.watch(
  (state) => state.cart.cart?.project?.id,
  (project_id) => {
    if (!project_id) { return }
    /* global drift */
    drift.on('ready', () => {
      drift.api.setUserAttributes({
        project_url: `${UI_URL}projects/${project_id}`,
        project_id,
      })
    })
  },
  { immediate: true }
)

// Unload Drift when a staff user signs in, and vice versa.
if (process.env.VUE_APP_DRIFT_ID) {
  store.watch(
    (state, getters) => getters['user/isStaff'],
    (isStaff) => {
      if (isStaff) {
        drift.unload?.()
      } else if (!drift.chatReady) {
        drift.load(process.env.VUE_APP_DRIFT_ID)
      }
    },
  )
}
