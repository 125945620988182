<template>
  <Multiselect
    :id="id"
    v-model="internal"
    track-by="id"
    placeholder="Type to search"
    :options="data && data.results || []"
    label="label"
    :show-labels="false"
    :preselect-first="false"
    :allow-empty="false"
    @search-change="debouncedFetchWorkspaces"
    :internal-search="false"
    :loading="isLoading"
  >
    <template #afterList>
      <li v-if="data && data.results && data.count > data.results.length">
        <small class="multiselect__option text-secondary">
          and {{(data.count - data.results.length).toLocaleString()}} more
        </small>
      </li>
    </template>
  </Multiselect>
</template>

<script>
import { debounce } from 'lodash'
import Multiselect from 'vue-multiselect'
import { getAppApi } from '@/services/http.js'
import { APP_LIMIT_DEFAULT } from '@/services/constants.js'

export default {
  name: 'QuickAddWorkspace',
  components: {
    Multiselect,
  },
  props: {
    id: null,
    value: Object,
    profile: Object,
  },
  data() {
    return {
      data: null,
      isLoading: false,
    }
  },
  computed: {
    internal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    currentWorkspace() {
      return this.profile && this.profile.current_workspace
    },
    defaultWorkspace() {
      if (!this.currentWorkspace) { return }
      return {
        ...this.currentWorkspace,
        project: this.profile?.current_project?.id,
        label: getLabel(this.profile),
      }
    },
  },
  watch: {
    currentWorkspace: 'onChange',
  },
  created() {
    this.onChange()
    this.loadWorkspaces({useCache: true})
    this.debouncedFetchWorkspaces = debounce(this.fetchWorkspaces, 200)
  },
  methods: {
    async onChange() {
      if (this.currentWorkspace) {
        this.internal = await this.selectWorkspace()
      }
    },
    async loadWorkspaces({ useCache } = {}) {
      if (!this.profile) { return }
      if (cachedWorkspaces && useCache) {
        this.data = await cachedWorkspaces
      } else {
        await this.fetchWorkspaces()
      }
      this.internal = await this.selectWorkspace()
    },
    async fetchWorkspaces(query) {
      this.isLoading = true
      this.$emit('update:busy', this.isLoading)
      try {
        this.data = await getWorkspaces(query)
      } catch (err) {
        this.$alert.simple(`Error loading workspaces\n${err}`, {variant: 'danger'})
      } finally {
        this.isLoading = false
        this.$emit('update:busy', this.isLoading)
      }
    },
    async selectWorkspace() {
      return this.defaultWorkspace || this.data?.results[0]
    },
  }
}

function getLabel(profile) {
  if (!profile) { return }
  const p = profile.current_project
  const w = profile.current_workspace
  if (!p || !w) { return '☂️' }
  return `${p.id}: ${p.name} / ${w.name}`
}

// cache of workspaces, shared by all instances of this component, to reduce
// requests during creation
let cachedWorkspaces

export function getWorkspaces(query) {
  const request = getAppApi().get(
    '/api/v2/myworkspaces/',
    {params: {search: query, page_size: APP_LIMIT_DEFAULT}}
  ).then(response => {
    return response.data
  })
  if (!query) {
    // cache only unfiltered results
    cachedWorkspaces = request
  }
  return request
}
</script>
