<template>
  <b-alert :key="id" dismissible :show="!!value" variant="danger">{{value}}</b-alert>
</template>

<script>
import { objectIdSafe } from '@/utils/objectId.js'

export default {
  name: 'ErrorDisplay',
  props: {
    value: null,
  },
  computed: {
    id() { return objectIdSafe(this.value) },
  },
}
</script>
