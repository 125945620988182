// initial state
const state = {
  error: [],
  routeRequiresAuth: false,
}

// getters
const getters = {
  authError(state, getters, rootState) {
    const route = state.routeRequiresAuth
    const user = rootState.user.user
    if (route && route.meta.auth === 'staff' && !(user && user.is_staff)) {
      return 'This page requires staff login.'
    }
    if (route && !user) {
      return 'This page requires login.'
    }
  },
  errors(state, getters) {
    return [
      ...state.error,
      getters.authError,
    ].filter(i => i)
      .map(i => (i.title || i.description) ? i : `⚠️ ${i}`)
  },
}

// actions
const actions = {
  setError ({ commit }, val) {
    commit('setError', val)
  },
  unsetError ({ commit }, val) {
    commit('unsetError', val)
  },
  clearError ({ commit }) {
    commit('clearError')
  },
  setRouteRequiresAuth({ commit }, val) {
    commit('setRouteRequiresAuth', val)
  },
}

// mutations
const mutations = {
  setError (state, val) {
    if (!val) {
      val = 'Undefined Error :('
    }
    if (val.isAxiosError) {
      val = formatAxiosError(val)
    }
    state.error.push(val)
  },
  unsetError(state, val) {
    const index = state.error.indexOf(val)
    if (index === -1) { return }
    state.error.splice(index, 1)
  },
  clearError(state) {
    state.error = []
  },
  setRouteRequiresAuth(state, val) {
    state.routeRequiresAuth = val
  },
}

function formatAxiosError(error) {
  // https://github.com/axios/axios#handling-errors
  if (error.response) {
    // The server responded with a status code that falls out of the range of 2xx
    error = {
      title: error.response.status,
      description: error.response.statusText,
    }
  } else if (error.request) {
    // The request was made but no response was received
    error = {
      title: error.message,
      description: `${error.config.method.toUpperCase()} ${error.config.url}`,
    }
  } else {
    // Something happened in setting up the request that triggered an error
    error = {
      title: 'Error',
      description: error.message,
    }
  }
  return error
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
