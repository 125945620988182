<template>
  <div class="position-relative" style="min-height: 1.5rem;">
    <b-overlay :show="loading" variant="white" no-wrap>
      <template #overlay><span/></template>
    </b-overlay>
    <!-- loading spinner is outside b-overlay so it is not visible during fade out -->
    <div v-if="loading" class="stretched-absolute" style="z-index: 11;">
      <slot name="spinner">
        <div class="centered">
          <b-spinner small class="text-secondary"/>
        </div>
      </slot>
    </div>

    <ErrorDisplay :value="error"/>

    <div v-if="items && total > limit_" class="d-flex align-items-center mb-3">
      <div class="ml-auto"></div>
      <b-pagination
        class="mb-0"
        v-model="page"
        :total-rows="total"
        :per-page="limit_"
      />
    </div>

    <slot
      v-if="Array.isArray(items) ? items.length : items"
      :items="items"
      :total="total"
      :busy="loading"
    >
      <pre>{{items}}</pre>
    </slot>

    <slot v-else-if="!loading" name="empty">
      No results
    </slot>

    <div v-if="items && total > limit_" class="d-flex align-items-center mt-3">
      <div class="ml-auto"></div>
      <b-pagination
        class="mb-0"
        v-model="page"
        :total-rows="total"
        :per-page="limit_"
      />
    </div>
  </div>
</template>

<script>
import ItemsApi from './ItemsApi.vue'

export default {
  name: 'ItemsApiGrid',
  extends: ItemsApi,
}
</script>
