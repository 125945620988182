<template>
  <div>
    <SiteNav
      v-if="!$route.path.match(/^\/accounts/) && !showLoginPrompt"
      class="site-nav"
    />
    <div class="site-alert" :style="{top: navHeight}">
      <v-alert-list/>
      <div class="position-relative mx-2">
        <b-toaster name="b-toaster-top-right" class="position-static"/>
      </div>
    </div>
    <AccountsLoginPrompt
      class=""
      v-if="showLoginPrompt"
    />
    <MessageOverlay
      v-else-if="errors.length"
      class="site-error"
      :messages="errors"
    />
    <router-view :key="authId" v-else></router-view>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import AccountsLoginPrompt from '@/pages/accounts/login-prompt.vue'
import SiteNav from '@/components/SiteNav.vue'
import MessageOverlay from '@/components/MessageOverlay.vue'

export default {
  name: 'Wrapper',
  components: {
    AccountsLoginPrompt,
    SiteNav,
    MessageOverlay,
  },
  computed: {
    ...mapState('user', [
      'user',
    ]),
    ...mapGetters('errors', [
      'authError',
      'errors',
    ]),
    authId() {
      // Force view reload when authentication changes. Limits data leakage on
      // automatic logout. Some data will still be temporarily visible while
      // auth check request is pending, particularly localStorage data.

      // prevent view reload on login, logout, etc pages
      if (this.$route.path.indexOf('/accounts') === 0) { return }
      if (this.$route.name === 'artist-login') { return }

      return this.$store.state.user.profile?.user_id
    },
    showLoginPrompt() {
      return this.authError && !this.user
    },
    navHeight() {
      return this.$store.state.appearance.navHeight
    },
  },
}
</script>

<style lang="scss" scoped>
.site-nav {
  z-index: ($zindex-fixed + 3);
  position: relative;
}
.site-alert {
  z-index: ($zindex-fixed + 2);
  position: sticky;
  top: 0;
}
  .site-error {
    z-index: $zindex-fixed;
  }
</style>
