<template>
  <div>
    <select
      aria-label="Results per page"
      class="form-control d-inline-block w-auto"
      :value="limitNum"
      @input="onLimit($event.target.value)"
    >
      <option v-for="option in limits" :key="option" :value="option">{{option.toLocaleString()}}</option>
    </select>
    <span aria-hidden="true">
      &nbsp;per page
    </span>
  </div>
</template>

<script>
import { insertSortedNumeric } from '@/utils/insertSorted.js'

export default {
  name: 'PaginationLimit',
  props: {
    limit: null,
    options: {
      type: Array,
      default: () => [
        20,
        50,
        100,
      ],
    },
  },
  computed: {
    limitNum() {
      return Number(this.limit)
    },
    limits() {
      return insertSortedNumeric(this.options, this.limitNum)
    },
  },
  methods: {
    onLimit(val) {
      this.$router.push({query: {...this.$route.query, limit: val, offset: undefined}})
    },
  },
}
</script>
