<template>
  <Multiselect
    :class="{'is-valid': state === true, 'is-invalid': state === false}"
    v-model="value_"
    v-bind="attrs"
    v-on="listeners"
  >
    <!-- pass all slots from parent component -->
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"/>
    </template>
  </Multiselect>
</template>

<script>
import { omit } from 'lodash'
import Multiselect from 'vue-multiselect'

export default {
  name: 'MultiselectObjects',
  components: {
    Multiselect,
  },
  inheritAttrs: false,
  props: {
    value: null,
    options: null,
    trackBy: {
      type: String,
      required: true,
    },
    label: null,
    required: null,
    multiple: Boolean,
    placeholder: {
      default()  { return `Select ${this.multiple ? 'one or more' : 'one'}` },
    },
    state: {type: Boolean, default: undefined},
  },
  computed: {
    attrs() {
      const props = omit(this.$props, 'value')
      return {...props, ...this.$attrs}
    },
    listeners() {
      return omit(this.$listeners, 'input')
    },
    value_: {
      get() {
        const { value } = this
        if (value === undefined || value === null) { return }
        return Array.isArray(value)
          ? value.map(i => this.getOption(i))
          : this.getOption(value)
      },
      set(val) {
        const { trackBy } = this
        const result = Array.isArray(val)
          ? val.map(i => i[trackBy])
          : val?.[trackBy]
        this.$emit('input', result)
      },
    },
  },
  watch: {
    required: 'setRequired',
    value() { this.setRequired(this.required) },
  },
  mounted() {
    this.setRequired(this.required)
  },
  methods: {
    getOption(val) {
      const { options, trackBy, label } = this
      return options?.find(option => String(option[trackBy]) === String(val))
        || { [trackBy]: val, [label]: `(${val})`, }
    },
    setRequired(isRequired) {
      // vue-multiselect does not apply required prop to the input,
      // do it manually so that form validation works
      const inputEl = this.$el.querySelector?.('.multiselect__input')
      if (!inputEl) { return }
      const hasValue = Array.isArray(this.value) ? this.value.length : this.value
      inputEl.required = isRequired && !hasValue
    },
  },
}
</script>
