<template>
  <div class="pop-show text-break">
    <div class="container-fluid py-4">
      <div class="row">
        <div class="pop-image-container col-sm-6">
          <b-overlay :show="Boolean(item._processing)" class="d-flex align-items-center">
            <template #overlay>
              <span></span>
            </template>
            <img
              class="pop-image"
              :src="src"
              :key="src"
              :alt="internal.caption || internal.title"
            >
          </b-overlay>
          <slot name="image-sibling"/>
        </div>
        <div class="pop-detail-container col-sm-6">
          <div>
            <div class="float-right">
              <slot name="info" v-if="item === internal"/>
            </div>
            <MediaInfo
              :item="internal"
            />
            <h6>Quick Add</h6>
            <QuickAdd
              :key="internal.id"
              v-if="internal.published"
              :item="internal"
              :selected="selectedQuickAdd"
            />
            <div v-else>
              <p>This image is unpublished and cannot be purchased.</p>
              <div v-if="hidden">
                <p>A replacement may be available in the Related Images section below. Contact support for more info.</p>
                <p>Image ID: {{internal.id}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="related-title">Related Images</div>
      <MediaRelated
        :item="item"
        v-model="internal"
        :q='hidden ? `"${item.title}"` : $route.query.q'
        :show-status="showStatus"
      />
      <div class="related-title">Keywords</div>
      <div class="item-keywords">
        <a v-for="keyword in uniq(internal.keywords)" :key="keyword" :href='`?q="${keyword}"`' class="btn  btn-outline-secondary">
          {{keyword}}
        </a>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import { uniq } from 'lodash'

import MediaInfo from '@/components/MediaInfo.vue'
import MediaRelated from '@/components/MediaRelated.vue'
import QuickAdd from '@/components/QuickAdd.vue'
import { getSrc } from '@/services/media.js'

export default {
  name: 'MediaQuick',
  components: {
    MediaInfo,
    MediaRelated,
    QuickAdd,
  },
  props: {
    item: Object,
    showStatus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      internal: this.item,
      selectedQuickAdd: {
        substrate: null,
        size: null,
        workspace: null,
      },
    }
  },
  computed: {
    src() {
      return getSrc(this.internal)
    },
    hidden() {
      return this.showStatus && !this.item?.published
    },
  },
  watch: {
    item: {
      handler: 'onChange',
      immediate: true,
    }
  },
  methods: {
    onChange(val) {
      this.internal = val
    },
    uniq,
  },
}
</script>

<style lang="scss" scoped>
$height-sm: 500px;

  .pop-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-down(xs) {
      margin-bottom: 0.5rem;
    }
    @include media-breakpoint-up(sm) {
      height: $height-sm;
    }
  }
  .pop-image {
    flex: 0 0 auto; // IE11 flexbox + max-width fix
    @include media-breakpoint-down(xs) {
      max-width: 100%;
    }
    @include media-breakpoint-up(sm) {
      max-width: 100%;
      max-height: $height-sm;
    }
  }
  .related-title {
    text-align: center;
    color: #555;
    margin-bottom: 10px;
  }

  .item-keywords .btn {
    margin: 2px;
  }
</style>
