// initial state
const getDefaults = () => ({
  showAllFields: false,
  showRaw: false,
  uploadsView: null,
  artistArtView: null,
  artistUploadTips: true,
  searchAdvanced: true,
  searchRefresh: null,
  dismissed: null,
})

const state = getDefaults()

// getters
const getters = {
  omitFields(state) {
    return state.showAllFields
      ? () => false
      : field => field.read_only
  },
}

// actions
const actions = {
}

// mutations
const mutations = {
  setPreference(state, data) {
    Object.keys(data).forEach(key => {
      state[key] = data[key]
    })
  },
  clear(state) {
    const defaults = getDefaults()
    Object.keys(state).forEach(key => {
      state[key] = defaults[key]
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
