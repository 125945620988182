// from bootstrap 4 $grid-breakpoints
const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

export function mediaBreakpointUp(size) {
  return window && window.innerWidth >= breakpoints[size]
}
