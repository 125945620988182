import axios from 'axios'
import { identity, memoize, debounce } from 'lodash'

import router from '@/router.js'
import store from '@/store'
import VAlert from '@/features/VAlert'
import { API_URL, SEARCH_URL, AUTH_URL } from '@/services/constants.js'

function paramsSerializer(params) {
  // based on https://github.com/axios/axios/blob/v0.18.0/lib/helpers/buildURL.js#L37-L56
  const result = new URLSearchParams()
  Object.keys(params).forEach(k => {
    let val = params[k]
    if (val === null || typeof val === 'undefined') {
      return
    }
    if (!Array.isArray(val)) {
      val = [val]
    }
    val.forEach(v => result.append(k, v))
  })
  return result
}

function addToken(config) {
  const token = store.state.user.token
  if (token && token.access) {
    config.headers.Authorization = `JWT ${token.access}`
  }
  return config
}

function removeToken(config) {
  delete config.headers.Authorization
  return config
}


// cache refresh results for a limited time
function fetchRefresh(token) {
  return axios.post(`${AUTH_URL}auth/jwt/refresh/`, {refresh: token})
    .catch(() => {
      // if refresh fails, attempt to get new tokens via session auth
      // e.g. expired refresh token in long-lived browser tab
      return authApiCreds.get('/auth/jwt/get/')
    })
    .then(response => {
      store.commit('user/setToken', {...store.state.user.token, ...response.data})
    })
    .finally(() => {
      setTimeout(() => cachedFetchRefresh.cache.delete(token), 10000)
    })
}
const cachedFetchRefresh = memoize(fetchRefresh)

function onError(err) {
  if (err.response && err.response.status === 401) {
    // on 401 Unauthorized error...
    const token = store.state.user.token
    if (token && token.refresh) {
      // if refresh token available, attempt to get new token
      return cachedFetchRefresh(token.refresh)
        .then(() => {
          // retry request with new token
          return axios(addToken(err.config))
        })
        .catch(() => {
          // if error getting new token, logout and retry request with no token
          return logout()
            .then(() => axios(removeToken(err.config)))
        })
    } else if (token && token.access) {
      // if no refresh token and logged in, logout
      logout()
    }
    if (err.config.headers.Authorization) {
      // if request contained a token, retry request with no token
      return axios(removeToken(err.config))
    }
  }
  return Promise.reject(err)
}

function logoutAlert() {
  const route = router.currentRoute
  const login = router.resolve({name: 'accounts-login', query: {
    redirect: route.query.redirect || route.fullPath, // avoid loop on login page
  }}).href
  VAlert.simple(`Your session expired. Please sign in again. <a href="${login}" class="alert-link stretched-link">Sign in</a>`, {variant: 'info'})
}
const debouncedLogoutAlert = debounce(logoutAlert, 1000, {leading: true, trailing: false})
function logout() {
  return store.dispatch('user/clear')
    .then(debouncedLogoutAlert)
}

const baseConfig = {
  baseURL: SEARCH_URL,
  paramsSerializer: {
    serialize: paramsSerializer,
  },
}

const http = axios.create(baseConfig);
http.interceptors.request.use(addToken)
http.interceptors.response.use(identity, onError)

const http2 = axios.create({
  baseURL: API_URL,
  // TODO: remove credentials when "invited users" are obsolete
  withCredentials: true,
})
http2.interceptors.request.use(addToken)
http2.interceptors.response.use(identity, onError)

const appApiCreds = axios.create({
  baseURL: API_URL,
  withCredentials: true,
})
// TODO: remove getAppApi and rename http2 to appApi
function getAppApi() {
  // invited users rely on a session/cookies, other users do not
  return store.getters['user/isSession'] ? appApiCreds : http2
}

const authApi = axios.create({
  baseURL: AUTH_URL,
})
authApi.interceptors.request.use(addToken)
authApi.interceptors.response.use(identity, onError)

const authApiCreds = axios.create({
  baseURL: AUTH_URL,
  withCredentials: true,
})


export { http, http2, appApiCreds, getAppApi, authApi, authApiCreds, paramsSerializer, debouncedLogoutAlert };

