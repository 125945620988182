<template>
  <DataWrapper
    :get-data="getImage"
    #default="{ data, busy }"
    class="mt-nav mb-nav site-width container-fluid"
  >
    <div v-if="busy" class="text-center py-4">
      <b-spinner small class="text-secondary"/>
    </div>
    <MediaQuick v-if="data" :item="data" class="bg-darker"/>
  </DataWrapper>
</template>

<script>
import MediaQuick from '@/components/MediaQuick.vue'
import { http } from '@/services/http.js'

export default {
  name: 'ImagesDetail',
  components: {
    MediaQuick,
  },
  props: {
    id: String,
  },
  methods: {
    getImage() {
      return http.get(`/api/images/${this.id}/`)
        .then(({ data }) => {
          this.setDocumentTitle(data)
          return data
        })
    },
    setDocumentTitle(item) {
      const title = item?.title || this.id
      if (title) {
        this.$nextTick(() => {
          document.title = `${title} - Great American Art`
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-darker {
  @media screen {
    background-color: #333;
    color: $light;
  }
}
</style>
