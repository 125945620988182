export function mask(val, {showFirst = 0, showLast = 0} = {}) {
  if (val === undefined || val === null) { return '' }
  val = String(val)

  showFirst = Math.max(showFirst, 0)
  showLast = Math.max(showLast, 0)
  const hideTo = Math.max(val.length - showLast, showFirst)

  const head   = val.slice(0, showFirst)
  const masked = val.slice(showFirst, hideTo).replace(/./g, '*')
  const tail   = val.slice(hideTo)

  return head + masked + tail
}
