// from https://stackoverflow.com/a/35306050/490592

// Note that object must be an object or array,
// NOT a primitive value like string, number, etc.

const  objIdMap = new WeakMap
let objectCount = 0

export function objectId(object) {
  if (!objIdMap.has(object)) {
    objIdMap.set(object, ++objectCount)
  }
  return objIdMap.get(object)
}

export function objectIdSafe(val) {
  try {
    return objectId(val)
  } catch (err) {
    return val
  }
}
