<template>
  <b-form-input
    :value="value_"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { mask } from '@/utils/mask.js'

export default {
  name: 'InputMasked',
  inheritAttrs: false,
  props: {
    value: String,
    maskOptions: Object,
  },
  data() {
    return {
      loaded: false,
      value_: undefined,
    }
  },
  watch: {
    value: {
      handler: 'onValue',
      immediate: true,
    },
  },
  methods: {
    onValue(val) {
      if (!this.loaded && val) { // only mask once
        this.loaded = true
        val = mask(val, this.maskOptions)
      }
      this.value_ = val
    },
  },
}
</script>
