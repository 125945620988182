<template>
  <div
    class="fill-color"
    :class="{dimmed: busy, 'text-truncate': compact}"
    v-b-tooltip.hover
    :title="(compact) ? tooltip : undefined"
  >
    <b-spinner small
      v-if="busy"
      class="align-middle"
    />
    <IconCheck
      v-else-if="active"
    />
    <IconCircle
      v-else
      class="icon-larger"
    >
      <text v-if="collection?.name" class="icon-label" x="50%" y="55%" dominant-baseline="middle" text-anchor="middle">a{{collection?.name[0]}}</text>
    </IconCircle>
    <span class="ml-2">{{label}}</span>
  </div>
</template>

<script>
import IconCircle from '@primer/octicons/build/svg/circle-16.svg'
import IconCheck from '@primer/octicons/build/svg/check-16.svg'
import { http } from '@/services/http.js'
import { getSrc } from '@/services/media.js'

export default {
  name: 'QuickMenuCollection',
  components: {
    IconCircle,
    IconCheck,
  },
  props: {
    item: Object,
    collection: Object,
    compact: Boolean,
  },
  data() {
    return {
      busy: false,
    }
  },
  computed: {
    active() {
      return this.collection?.contents?.includes(this.item.id)
    },
    label() {
      return this.collection?.name
    },
    tooltip() {
      return (this.active)
        ? `Remove from ${this.collection?.name} collection`
        : `Add to ${this.collection?.name} collection`
    },
  },
  methods: {
    async toggle() {
      if (this.busy) { return }
      try {
        this.busy = true
        let response
        if (this.active) {
          // Remove from collection
          response = await http.patch(`/api/collections/${this.collection.id}/remove/`, {remove: [this.item.id]})
          this.toast(
            this.item,
            `Removed from ${this.collection.name} collection`,
            {variant: 'secondary', to: `/collections/${this.collection.id}/`}
          )
        } else {
          // Add to collection
          response = await http.patch(`/api/collections/${this.collection.id}/prepend/`, {prepend: [this.item.id]})
          this.toast(
            this.item,
            `Added to ${this.collection.name} collection`,
            {variant: 'success', to: `/collections/${this.collection.id}/`}
          )
        }
        // update the cache store without making a new request, impure, but efficient
        Object.assign(this.collection, response.data)
      } catch (err) {
        this.$alert.simple(`${err}\nPlease try again. Contact customer support if this persists.`, {variant: 'danger'})
      } finally {
        this.busy = false
      }
    },
    toast(item, message, options) {
      const image = this.$createElement('img', {attrs: {src: getSrc(item, 'thumb')}, class: 'toast-image mr-2'})
      this.$bvToast.toast(
        [image, message],
        {variant: 'success', noCloseButton: true, solid: true, bodyClass: 'd-flex align-items-center text-reset', ...options}
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.dimmed {
  opacity: $btn-disabled-opacity;
}
.icon-larger {
  transform: scale(1.1);
}
.icon-label {
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
