<template>
  <div class="container-fluid mt-nav mb-5 mb-chat site-width">
    <h1 class="mb-4">Projects</h1>
    <ProjectsWidget
      :query.sync="query"
    />
  </div>
</template>

<script>
import ProjectsWidget from '@/pages/projects/-widget.vue'

export default {
  name: 'ProjectsList',
  components: {
    ProjectsWidget,
  },
  computed: {
    query: {
      get() {
        return this.$route.query
      },
      set(query) {
        this.$router.push({query})
      },
    },
  },
}
</script>
