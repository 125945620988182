<template>
  <form class="search-form mr-3"
    @submit.stop.prevent="onSearch(value_)"
  >
    <div class="position-relative flex-fill">
      <input
        type="search"
        class="form-control form-control-sm-lg search-input"
        autocomplete="off"
        placeholder="Search Art"
        aria-label="Search Art"
        v-model="value_"
      >
      <button type="submit"
        class="btn btn-sm-lg search-button btn-primary"
        title="Search"
        aria-label="Search"
      >
        <IconSearch class="fill-color" width="1em" height="1em" viewBox="0 0 16 16" aria-hidden="true"/>
      </button>
    </div>
  </form>
</template>

<script>
import IconSearch from '@primer/octicons/build/svg/search-16.svg'

export default {
  name: 'SiteSearch',
  components: {
    IconSearch,
  },
  data() {
    return {
      value_: '',
    }
  },
  computed: {
    value() {
      return (this.$route.name === 'search') ? this.$route.query.q : ''
    },
  },
  watch: {
    value: {
      handler(val) { this.value_ = val },
      immediate: true,
    },
  },
  methods: {
    onSearch(val) {
      // preserve options when on search page
      const query = (this.$route.name === 'search')
        ? {...this.$route.query, q: val, offset: undefined}
        : {q: val}
      this.$router.push({name: 'search', query })
      // force refresh results even if nothing has changed
      this.$store.commit('preferences/setPreference', {searchRefresh: Date.now()})
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_env.scss';

.form-control-sm-lg {
  @include media-breakpoint-up(sm) {
    // from _forms.scss .form-control-lg
    height: $input-height-lg;
    padding: $input-padding-y-lg $input-padding-x-lg;
    // @include font-size($input-font-size-lg);
    line-height: $input-line-height-lg;
    @include border-radius($input-border-radius-lg);
  }
}
.btn-sm-lg {
  // from _buttons.scss .btn-lg
  @include media-breakpoint-up(sm) {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, 1.375rem, 1.375rem, $btn-border-radius-lg);
    height: 42px;
  }
}

.search-form {
  display: flex;
  align-items: center;
  width: 100%;
}
.search-input {
  border-color: $input-bg;
  padding-right: 3.5em;
  @include media-breakpoint-up(sm) {
    padding-right: 4.5em;
  }
}
.search-button {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
