/**
 * Accumulate Focus
 * Accumulate a list of selectors, then of those, focuses on the first element on the page
 * e.g.
 * accumulateFocus('#form-1-input-name')
 * accumulateFocus('#form-1-input-company')
 * → One input is focused
 */

import { accumulateBatch } from '@/services/accumulateBatch.js'

export function accumulateFocus(selector, wait = 0) {
  accumulateBatch('focus', selector, selectors => {
    const el = document.querySelector(selectors.join())
    el?.focus()
  }, wait)
}
