<template>
  <component
    v-if="item.component"
    :is="item.component"
  >{{item.name}}</component>
  <b-dropdown-group
    v-else-if="item.children"
    :header="item.name"
  >
    <DropdownChild
      v-for="child in item.children"
      :key="child.name"
      :item="child"
    />
  </b-dropdown-group>
  <b-dropdown-item
    v-else
    :to="item.to"
    :href="item.href"
    :exact="item.exact"
    active-class="active"
  >
    {{item.name}}
  </b-dropdown-item>
</template>

<script>
export default {
  name: 'DropdownChild',
  props: {
    item: Object,
  },
}
</script>
