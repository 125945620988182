<template>
  <Accounts>
    <AccountsLogin
      :redirect="redirect"
      :on-success="null"
    >
      <template #title>
        <h1 class="h3 mb-4">Please sign in to continue</h1>
      </template>
    </AccountsLogin>
  </Accounts>
</template>

<script>
import Accounts from '@/pages/accounts.vue'
import AccountsLogin from '@/pages/accounts/login.vue'

export default {
  name: 'AccountsLoginPrompt',
  components: {
    Accounts,
    AccountsLogin,
  },
  props: {
    redirect: {
      type: String,
      default() { return this.$route.fullPath },
    },
  },
}
</script>
