export function addScript(el, params) {
  return new Promise((resolve, reject) => {
    const scriptEl = createScriptEl(params)
    scriptEl.onload = resolve
    scriptEl.onerror = reject
    el.appendChild(scriptEl)
  })
}

export const addScriptOnce = memoize(addScript, (el, params) => JSON.stringify(params))

function createScriptEl(params) {
  const scriptEl = document.createElement('script')
  params && Object.keys(params).forEach(param => {
    scriptEl.setAttribute(param, params[param])
  })
  return scriptEl
}

// minimal version of https://lodash.com/docs/#memoize
function memoize(fn, resolver = i => i) {
  const memo = new Map

  function memoized(...args) {
    const key = resolver(...args)
    if (!memo.has(key)) {
      memo.set(key, fn(...args))
    }
    return memo.get(key)
  }
  memoized.cache = memo
  return memoized
}
