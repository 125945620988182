<template>
  <div>
    Sort by:
    <b-dropdown :text="sortLabel" right variant="link">
      <b-dropdown-item
        v-for="item in options"
        :key="item.label"
        :to="{query: getQuery(item.value)}"
        active-class="active"
        exact
      >
        {{item.label}}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'SortMenu',
  props: {
    sort: String,
  },
  data() {
    return {
      options: [
        {value: undefined, label: 'Newest'},
        {value: 'best_match', label: 'Best Match'},
        {value: 'popular', label: 'Most popular'},
      ],
    }
  },
  computed: {
    sortLabel() {
      return getLabel(this.options, this.sort)
    },
  },
  methods: {
    getQuery(val) {
      if (this.$route.query.sort === val) {
        // return existing query when sort matches, so that we get an active link in "exact" mode
        return this.$route.query
      }
      // set new sort, reset offset
      const result =  Object.assign({}, this.$route.query, {sort: val, offset: undefined})
      return result
    },
  },
}

function getLabel(items, val) {
  const item = findItem(items, val)
  return (item) ? item.label : val
}

function findItem(items, val) {
  if (!items) { return val }
  return items.find(i => {
    return i.value === val || i === val
  })
}
</script>
