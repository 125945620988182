<template>
  <b-form-input
    type="datetime-local"
    step="any"
    v-model="internal"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  name: 'InputDatetimeLocal',
  inheritAttrs: false,
  props: {
    value: String,
  },
  computed: {
    internal: {
      get() {
        // strip fractional seconds and timezone,
        // datetime-local doesn't render values with them
        return this.value?.replace(/\.\d+/, '').replace(/(Z|[+-]\d{2}:\d{2})$/, '')
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
