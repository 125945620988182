<template>
  <OptionsForm
    ref="form"
    @submit="onSubmit"
    v-model="internal"
    :fields="fields"
  >
    <slot/>
  </OptionsForm>
</template>

<script>
import OptionsForm from '@/components/OptionsForm.vue'

export default {
  name: 'AnonUserForm',
  components: {
    OptionsForm,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fields: {
        email: {
          label: 'Email',
          required: true,
          type: 'email',
          props: {autofocus: true},
        },
      },
      internal: {},
    }
  },
  watch: {
    value: {
      handler(val) {
        this.internal = JSON.parse(JSON.stringify(val))
      },
      immediate: true,
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.internal)
    },
    async validate() {
      if (!this.$refs.form.clientValidate()) {
        throw new Error('Form failed client-side validation')
      }
      return this.internal
    },
  },
}
</script>
