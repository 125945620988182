/**
 * Convert a number into dollar signs
 * e.g. price(2) → '$$'
 */

export function price(val) {
  if (val === null) { return '' }
  val = Number(val)
  let result = ''
  for (let i = 0; i < val; i++) {
    result += '$'
  }
  return result
}
