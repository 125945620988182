<template>
  <div>
    <div v-if="typeof value === 'string'">
      <img :src="value" class="thumb" alt=" ">
      <button type="button"
        title="Clear"
        @click="$emit('input', null)"
        class="btn btn-light btn-micro rounded-pill ml-2"
      >&times;</button>
    </div>
    <b-form-file
      v-else
      v-bind="$attrs"
      v-on="$listeners"
      :value="value"
    />
  </div>
</template>

<script>
export default {
  name: 'InputImage',
  components: {},
  inheritAttrs: false,
  props: {
    value: [String, File, Array],
  },
}
</script>

<style lang="scss" scoped>
.thumb {
  max-width: 38px;
  max-height: 38px;
}
</style>
