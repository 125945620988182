// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString#Checking_for_support_for_locales_and_options_arguments
function IntlSupport() {
  return !!(typeof Intl == 'object' && Intl && typeof Intl.NumberFormat == 'function');
}

const formatter = IntlSupport() && new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
})

export function currency(val, divisor = 1) {
  const amount = Number(val)
  if (Number.isNaN(amount) || (!val && val !== 0) || !formatter) { return val }
  return formatter.format(amount / divisor)
}
