<template>
  <div class="form-inline">
    <router-link
      title="Previous page"
      aria-label="Previous page"
      class="btn btn-outline-primary"
      :class="{disabled: isFirstPage, compact: compact}"
      :event="isFirstPage ? '' : 'click'"
      :to="linkGen(currentPage - 1)"
    >
      <IconChevronLeft class="chevron"/>
      <span v-if="!compact"> Previous</span>
    </router-link>
    <div class="form-group page-display"
      :class="{'mx-2': !compact}"
    >
      <input type="text"
        title="Page number"
        aria-label="Page number"
        class="form-control text-center"
        :class="{'form-control-plaintext': compact}"
        :size="digits(pages)"
        :maxlength="digits(pages)"
        :value="currentPage"
        @change="onPageChange($event.target.value)"
      >
      <span
        class="ml-1 mr-1"
        v-if="!compact"
      >
        / {{pages.toLocaleString()}}
      </span>
    </div>
    <router-link
      title="Next page"
      aria-label="Next page"
      class="btn btn-outline-primary"
      :class="{disabled: isLastPage, compact: compact}"
      :event="isLastPage ? '' : 'click'"
      :to="linkGen(currentPage + 1)"
    >
      <span v-if="!compact">Next </span>
      <IconChevronRight class="chevron"/>
    </router-link>
  </div>
</template>

<script>
import IconChevronLeft from '@/images/chevron-left.svg'
import IconChevronRight from '@/images/chevron-right.svg'

export default {
  name: 'PaginationNav',
  components: {
    IconChevronLeft,
    IconChevronRight,
  },
  props: {
    limit: null,
    total: null,
    compact: Boolean,
  },
  data() {
    return {
    }
  },
  computed: {
    limitNum() {
      return Number(this.limit)
    },
    pages() {
      return Math.ceil(this.total / this.limitNum)
    },
    currentPage() {
      const offset = this.$route.query.offset
      if (!offset) { return 1 }
      return Math.floor(offset / this.limitNum) + 1
    },
    isFirstPage() {
      return this.currentPage <= 1
    },
    isLastPage() {
      return this.currentPage >= this.pages
    },
  },
  methods: {
    digits(val) {
      return val.toString().length
    },
    onPageChange(val) {
      if (val < 1 || val > this.pages || Number.isNaN(Number(val))) {
        this.$alert.simple(`Please enter a page number between 1 and ${this.pages.toLocaleString()}`)
        return false
      }
      this.$router.push(this.linkGen(val))
    },
    linkGen(pageNum) {
      return {
        query: Object.assign({}, this.$route.query, {offset: (pageNum - 1) * this.limitNum})
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .page-display {
    margin: 0;
    .form-control {
      display: inline-block;
      width: auto;
    }
  }
  .btn-outline-primary {
    fill: $link-color;
    &.compact {
      border-color: transparent;
    }
    &:hover {
      fill: white;
    }
  }
  .chevron {
    width: 0.75em;
    height: 0.75em;
    transform: translate(0, -0.05em);
  }
</style>
