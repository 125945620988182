<template>
  <div class="container-fluid fixed-top h-100 overflow-auto d-flex flex-column">
    <div class="login-width w-100 mx-auto my-auto text-center">
      <div class="login-wing my-4">
        <b-link :[homeAttr]="homeUrl">
          <GaaLogo height="60px" style="margin: 5px 0"/>
        </b-link>
      </div>

      <slot>
        <router-view/>
      </slot>

      <div class="login-wing my-4"></div>
    </div>
  </div>
</template>

<script>
import { HOME_URL } from '@/services/constants.js'

import GaaLogo from '@/images/gaa-logo.svg'

export default {
  name: 'Accounts',
  components: {
    GaaLogo,
  },
  data() {
    return {
      homeUrl: HOME_URL,
      homeAttr: HOME_URL.match(/^http/) ? 'href' : 'to',
    }
  },
}
</script>

<style lang="scss" scoped>
.login-wing {
  height: 100px;
}
</style>
