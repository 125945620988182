<template>
  <div>
    <button type="button"
      ref="button"
      class="btn"
      :class="buttonClass"
    >
      <slot><IconInfo width="1em" height="1em" viewBox="0 0 16 16" class="fill-color"/></slot>
    </button>
    <b-popover
      :target="() => $refs.button"
      triggers="hover focus"
      placement="bottom"
      boundary="viewport"
      title="Search Tips"
    >
      <p>Search by artist or keyword.</p>
      <p>
        Use quotes for an exact phrase:<br>
        <code>"frost flower"</code>
      </p>
      <p>
        Use pipe for matching any terms:<br>
        <code>cars | trucks</code>
      </p>
      <p>
        Use minus to exclude:<br>
        <code>-cars</code>, <code>-"Model T"</code>
      </p>
    </b-popover>
  </div>
</template>

<script>
import IconInfo from '@primer/octicons/build/svg/info-16.svg'

export default {
  name: 'SearchTips',
  components: {
    IconInfo,
  },
  props: {
    buttonClass: null,
  },
}
</script>
