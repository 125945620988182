<template>
  <DataWrapper
    :get-data="getOptionsCached"
    ref="apiHandler"
    #default="{ data, busy }"
  >
    <MultiselectObjects
      :options="data && data.results || []"
      :loading="busy"
      @search-change="onSearch"
      v-bind="$attrs"
      v-on="$listeners"
      :class="childClass"
    >
      <!-- pass all slots from parent component -->
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>

      <template #afterList>
        <slot name="afterList" :data="data">
          <li v-if="data && data.results && data.count > data.results.length">
            <small class="multiselect__option text-secondary">
              and {{(data.count - data.results.length).toLocaleString()}} more
            </small>
          </li>
        </slot>
      </template>
    </MultiselectObjects>
  </DataWrapper>
</template>

<script>
import { memoize, debounce } from 'lodash'

import MultiselectObjects from '@/components/MultiselectObjects.vue'

export default {
  name: 'MultiselectObjectsApi',
  components: {
    MultiselectObjects,
  },
  inheritAttrs: false,
  props: {
    getOptions: {
      type: Function,
      required: true,
    },
    childClass: null,
  },
  created() {
    this.getOptionsCached = memoize(this.getOptions, i => JSON.stringify(i))
  },
  mounted() {
    const fetchOptions = this.$refs.apiHandler.fetchData
    this.fetchOptionsDebounced = debounce(fetchOptions, 200)
  },
  methods: {
    async onSearch(search) {
      // hit the API if all results are not available in the initial request
      const data = await this.getOptionsCached()
      if (data.count <= data.results?.length) { return }
      return this.fetchOptionsDebounced(search ? {params: {search}} : undefined)
    },
  },
}
</script>
