<template>
  <div class="position-relative d-inline-block">
    <transition name="bounce">
      <div
        class="cart-quantity"
        :class="{shrink: quantity >= 10}"
        :key="quantity"
      >{{quantity || ''}}</div>
    </transition>
    <IconCart class="icon"/>
  </div>
</template>

<script>
import IconCart from '@/images/noun_Shopping Cart_2370_000000.svg'

export default {
  name: 'SiteCart',
  components: {
    IconCart,
  },
  props: {
    quantity: Number,
  },
}
</script>

<style lang="scss" scoped>
.cart-quantity {
  $size: 20px;

  position: absolute;
  bottom: 16px;
  left: 16px;
  transform: translate(-50%, 0);
  font-size: 60%;
  line-height: 1;
  font-weight: bold;
  color: white;
  background: $primary;
  padding: 0 4px;
  border-radius: $size / 2;
  height: $size;
  min-width: $size;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  &:empty {
    background: transparent;
  }
}
.shrink {
  font-size: 60%;
}

.bounce-enter-active,
.bounce-leave-active {
  transition: all 0.3s $easeOutBack;
}
.bounce-enter {
  transform: translate(-50%, -10px);
}
.bounce-leave-to {
  opacity: 0;
}
</style>
