<template>
  <Multiselect
    :class="{'is-valid': state === true, 'is-invalid': state === false}"
    v-model="internal"
    :options="options"
    :track-by="trackBy"
    :label="label"
    :loading="isLoading"
    @search-change="onSearch"
    :disabled="disabled"
    :multiple="multiple"
    :placeholder="`Select ${multiple ? 'one or more' : 'one'}`"
    :close-on-select="!multiple"
    v-bind="$attrs"
  >
    <template #afterList>
      <slot name="afterList" :data="data">
        <li v-if="data && data.results && data.count > data.results.length">
          <small class="multiselect__option text-secondary">
            and {{(data.count - data.results.length).toLocaleString()}} more
          </small>
        </li>
      </slot>
    </template>
  </Multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { debounce, uniqBy } from 'lodash'

import { http } from '@/services/http.js'

export default {
  name: 'OptionsMultiselect',
  components: {
    Multiselect,
  },
  inheritAttrs: false,
  props: {
    state: {type: Boolean, default: undefined},
    value: [Number, Array],
    api: String,
    disabled: Boolean,
    multiple: Boolean,
    required: Boolean,
    trackBy: {type: String, default: 'name'},
    label: {type: String, default: 'name'},
  },
  data() {
    return {
      isLoading: false,
      data: null,
      options: [],
      cachedOptions: [],
    }
  },
  computed: {
    internal: {
      get() {
        if (this.value === undefined || this.value === null) { return }
        return Array.isArray(this.value)
          ? this.value.map(val => getOption(this.cachedOptions, val, this.label))
          : getOption(this.cachedOptions, this.value, this.label)
      },
      set(val) {
        const result = Array.isArray(val)
          ? val.map(i => i.id)
          : val && val.id
        this.$emit('input', result)
      },
    }
  },
  watch: {
    value(val) {
      this.setRequired(this.required)
      if (!this.cachedOptions.some(option => option.id === val)) {
        this.fetchOptions()
      }
    },
    options(val) {
      this.cachedOptions = uniqBy([...val, ...this.cachedOptions], 'id')
    },
    required: 'setRequired',
  },
  created() {
    this.initialRequest = this.fetchOptions()
    this.debouncedFetchOptions = debounce(this.fetchOptions, 200)
  },
  mounted() {
    this.setRequired(this.required)
  },
  methods: {
    async onSearch() {
      this.$emit('error', undefined)
      // hit the API if all results are not available in the initial request
      const data = await this.initialRequest
      if (data.count <= data.results?.length) { return }
      this.debouncedFetchOptions.apply(null, arguments)
    },
    async fetchOptions(query) {
      this.isLoading = true
      try {
        const data = await this.getOptions(query, this.api)
        this.data = data
        this.options = data.results || data
        return data
      } catch (err) {
        this.$emit('error', err)
      } finally {
        this.isLoading = false
      }
    },
    getOptions(query, path) {
      return http.get(
        path,
        {params: {search: query, limit: 1000}}
      ).then((response) => {
        return response.data
      })
    },
    setRequired(isRequired) {
      // vue-multiselect does not apply required prop to the input,
      // do it manually so that form validation works
      const hasValue = Array.isArray(this.value) ? this.value.length : this.value
      this.$el.querySelector('.multiselect__input').required = isRequired && !hasValue
    },
  },
}

function getOption(options, val, labelKey) {
  return options.find(option => option.id === val) || {[labelKey]: `id:${val}`, id: val}
}
</script>
