<template>
  <div class="container-fluid site-width mt-nav pb-uncollapse">
    <div class="d-flex flex-wrap align-items-center mb-2">
      <h1 class="mb-0 no-shrink">{{cart && cart.project && cart.project.name}}</h1>
      <b-spinner small variant="secondary" class="ml-3" v-show="loading"/>
      <div class="mr-auto"></div>
      <CartId class="text-secondary mr-3"
        v-if="cart"
        :noun="noun"
        :cart="cart"
      />
    </div>
    <ErrorDisplay :value="error"/>
    <b-alert variant="info" :show="Boolean(latestOrder)">
      This {{noun}} has been ordered.
      <router-link :to="`/orders/${latestOrder}`" class="alert-link stretched-link">View order</router-link>
    </b-alert>
    <form class="row mb-5 mb-chat position-relative" @submit.stop.prevent="onSubmit">
      <b-overlay variant="white" :show="Boolean(latestOrder)" no-wrap><template #overlay><span/></template></b-overlay>
      <div class="col-sm order-sm-1 mb-3 total-column">
        <div class="bg-light p-3" v-if="cart">
          <div class="d-flex justify-content-between my-2">
            <span>Subtotal ({{formatCount(cart.quantity, 'item')}})</span>
            <span>{{currency(cart.subtotal)}}</span>
          </div>
          <div v-if="cart && Number(cart.volume_discount)" class="d-flex justify-content-between my-2">
            <span>
              Volume Discount
              <span>({{cart.volume_discount_rate}}%)</span>
            </span>
            <span>{{currency(cart.volume_discount * -1)}}</span>
          </div>
          <div class="d-flex justify-content-between my-2">
            <span>Shipping Estimate</span>
            <span>{{currency(cart.shipping)}}</span>
          </div>
          <div class="d-flex justify-content-between my-2 font-weight-bold">
            <span>Total</span>
            <span>{{currency(cart.grand_total)}}</span>
          </div>
          <button type="submit"
            class="btn btn-lg btn-block btn-primary"
            :disabled="loading || !(items && items.length)"
          >Check out</button>
        </div>
      </div>
      <div class="col-sm">
        <div v-if="loading && !items">
          <CartItemSkeleton class="border-top py-3" />
        </div>
        <template v-else-if="cart && cart.quantity || (items && items.length)">
          <b-pagination
            v-if="itemsCount > itemsLimit"
            v-model="page"
            :total-rows="itemsCount"
            :per-page="itemsLimit"
            align="right"
          />
          <b-overlay variant="white" :show="loadingItems">
          <template #overlay><span/></template>
          <transition-group :name="transitionName">
            <div v-for="(item, index) in items" :key="item.id">
              <CartItem
                class="border-top py-3"
                :item="item"
                :id="index"
                :update="onUpdate"
                :remove="onRemove"
              />
            </div>
          </transition-group>
          <div class="border-top"></div>
          </b-overlay>
          <b-pagination
            class="mt-3 mb-0-last-child"
            v-if="itemsCount > itemsLimit"
            v-model="page"
            :total-rows="itemsCount"
            :per-page="itemsLimit"
            align="right"
          />
          <div v-if="cart" class="py-4 d-flex align-items-center">
            <span class="mr-auto mr-sm-2 ml-sm-auto">Subtotal ({{formatCount(cart.quantity, 'item')}}):</span>
            <span>{{currency(cart.subtotal)}}</span>
          </div>
        </template>
        <div v-else-if="!error" class="bg-light p-5 text-center">
          <h3>Your {{noun}} is empty</h3>
          <router-link
            :to="{name: 'search', query: {sort: 'popular'}}"
          >Browse top selling art</router-link>
          <div class="mt-3" v-if="!user">
            <router-link
              class="btn btn-primary mr-2"
              :to="{name: 'accounts-login', query: {redirect: $route.fullPath}}"
            >Sign in</router-link>
            <a
              class="btn btn-outline-primary"
              :href="registerUrl"
            >Sign up</a>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import CartId from '@/components/CartId.vue'
import CartItem from '@/components/CartItem.vue'
import CartItemSkeleton from '@/components/CartItemSkeleton.vue'
import { API_URL, UI_URL, CART_LIMIT_DEFAULT } from '@/services/constants.js'
import { formatCount } from '@/utils/count.js'
import { currency } from '@/utils/currency.js'
import { http2 } from '@/services/http.js'

export default {
  name: 'Cart',
  components: {
    CartId,
    CartItem,
    CartItemSkeleton,
  },
  props: {
    id: String,
  },
  data() {
    return {
      transitionName: null,
      loading: false,
      loadingItems: false,
      error: null,
      cart: null,
      cartItems: null,
      page: 1,
      itemsLimit: CART_LIMIT_DEFAULT,
    }
  },
  computed: {
    ...mapState('user', [
      'user',
    ]),
    items() {
      return this.cartItems?.results
    },
    itemsCount() {
      return this.cartItems?.count
    },
    noun() {
      return 'project'
    },
    registerUrl() {
      return `${API_URL}accounts/register/?redirect=${UI_URL}`
    },
    latestOrder() {
      return null // cart.latest_order can be a quote, TODO: find another way to know if a project has been ordered
      // return this.cart?.latest_order
    },
  },
  watch: {
    page(val) {
      window.scrollTo(0, 0)
      return this.fetchCartItems(val)
    },
    id: 'fetchItem',
  },
  created() {
    this.fetchItem()
  },
  methods: {
    formatCount,
    currency,
    fetchItem() {
      const fetchCart = Promise.all([
        this.getCart(),
        this.getCartItems(this.page),
      ])
      this.error = null
      this.loading = true
      return fetchCart
        .catch(err => {
          this.error = err
          throw err
        })
        .finally(() => this.loading = false)
    },
    fetchCartItems(page) {
      this.error = null
      this.loadingItems = true
      return this.getCartItems(page)
        .catch(err => {
          this.error = err
          throw err
        })
        .finally(() => {
          if (page !== this.page) { return } // when switching pages rapidly, responses can return out of order
          this.loadingItems = false
        })
    },
    async getCart() {
      this.cart = await http2.get(`/api/v2/projects/${this.id}/`)
        .then(response => response.data)
    },
    async getCartItems(page) {
      const cartItems = await http2.get(`/api/v2/projects/${this.id}/items/`, {params: {page, page_size: CART_LIMIT_DEFAULT}})
        .then(response => response.data)
      if (page !== this.page) { return } // when switching pages rapidly, responses can return out of order
      this.cartItems = cartItems
    },
    onSubmit() {
      this.$router.push({path: 'checkout', append: true})
    },
    async onUpdate(data) {
      try {
        this.loading = true
        await http2.patch(`/api/v2/projects/${this.cart.token}/item/?id=${data.id}`, data)
        await this.fetchItem()
      } finally {
        this.loading = false
      }
    },
    async onRemove(data) {
      try {
        this.loading = true
        this.transitionName = 'collapse'
        await http2.delete(`/api/v2/projects/${this.cart.token}/item/?id=${data.id}`)
        await this.fetchItem()
      } finally {
        this.loading = false
        this.transitionName = null
      }

    }
  },
}
</script>

<style lang="scss" scoped>
.total-column {
  @include media-breakpoint-up(sm) {
    max-width: 320px;
  }
}
</style>
