<template>
  <div
    class="button"
    :class="{expanded: active}"
    v-on="$listeners"
  >
    <span class="triangle">▸</span>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'DisclosureTriangle',
  props: {
    active: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.button {
  display: inline-block;
  cursor: pointer;
  user-select: none;
}
.triangle {
  display: inline-block;
  transform-origin: 50% 50%;
  transform: rotate(0deg);
  .expanded & {
    transform: rotate(90deg);
  }
  .expanded.rotate-180 & {
    transform: rotate(180deg);
  }
}
</style>
