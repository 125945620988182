import { SEARCH_LIMIT_DEFAULT } from '@/services/constants.js'
import { http } from '@/services/http.js'
import { splitAdapterData } from '@/services/searchUtils.js'

/**
 * APIs
 */

function baseApi(params) {
  return http.get('content/search/v3/', {
    params
  }).then(gettyDecorator)
}

function internalApi(params) {
  return http.get('content/search/v3/', {
    params: {...params, api: 'internal' }
  })
}

function gettyApi(params) {
  return http.get('content/search/v3/', {
    params: {...params, api: 'getty' }
  }).then(gettyDecorator)
}

function gettyDecorator(response) {
  if (response.config.params.api === 'getty' && response.data.total) {
    response.data.facets = { source: [{label: "GET", count: response.data.total, value: "GET"}] }
  }
  return response
}


/**
 * Adapters
 */

export const singleAdapter = {
  async get(params) {
    const response = await baseApi({
      ...params,
      limit: params.limit || SEARCH_LIMIT_DEFAULT,
    })
    return response.data
  },
}


export const splitAdapter = {
  async get(params) {
    params = {
      ...params,
      limit: Number(params.limit || SEARCH_LIMIT_DEFAULT),
      offset: Number(params.offset || 0),
    }

    const apis = []
    if (isUnified(params)) {
      apis.push(baseApi)
    } else {
      if (hasInternal(params)) { apis.push(internalApi) }
      if (hasGetty(params)) { apis.push(gettyApi) }
    }
    if (!apis.length) {
      return {total: 0}
    }

    const paramsSplit = {
      ...params,
      limit: Math.floor(params.limit / apis.length),
      offset: Math.floor(params.offset / apis.length),
    }
    const settled = await Promise.allSettled(apis.map(i => i(paramsSplit)))
    return splitAdapterData(settled, params)
  },
}

function isUnified(params) {
  if (!isEmpty(params.favorites)) {
    return true
  }
  if (!isEmpty(params.artcollection)) {
    return true
  }
  return false
}

function hasInternal(params) {
  return isEmpty(params.source) || includesOthers(params.source, 'GET')
}

function hasGetty(params) {
  if (!isEmpty(params.price) && !(includes(params.price, '1') || includes(params.price, '2'))) {
    return false
  }
  if (!isEmpty(params.image_type) && !includes(params.image_type, 'photography')) {
    return false
  }
  if (!isEmpty(params.artist)) {
    return false
  }
  if (!isEmpty(params.royalty_code)) {
    return false
  }
  return isEmpty(params.source) || includes(params.source, 'GET')
}

function isEmpty(param) {
  return (Array.isArray(param))
    ? param.length === 0
    : !param
}

function includes(param, value) {
  return (Array.isArray(param))
    ? param.includes(value)
    : param === value
}

function includesOthers(param, value) {
  return (Array.isArray(param))
    ? param.some(i => i !== value)
    : param !== value
}
