<template>
  <div
    class="fill-color"
    :class="{dimmed: busy, 'text-truncate': compact}"
    v-b-tooltip.hover
    :title="(compact) ? `Add to ${collection} static items` : undefined"
  >
    <b-spinner small
      v-if="busy"
      class="align-middle"
    />
    <IconCheck
      v-else-if="active"
    />
    <IconTag
      v-else
      class="icon-tag"
    >
      <text v-if="collection !== 'government'" class="icon-label" transform="matrix(0.707107, 0.707107, -0.707107, 0.707107, 6.187429, -2.656207)" x="7.5" y="8.5">{{collection[0]}}</text>
    </IconTag>
    <span class="ml-2 text-capitalize">{{label}}</span>
  </div>
</template>

<script>
import { http2 } from '@/services/http.js'

import IconTag from '@primer/octicons/build/svg/tag-16.svg'
import IconCheck from '@primer/octicons/build/svg/check-16.svg'

export default {
  name: 'QuickMenuStatic',
  components: {
    IconTag,
    IconCheck,
  },
  props: {
    item: Object,
    collection: {
      type: String,
      required: true,
    },
    compact: Boolean,
  },
  data() {
    return {
      busy: false,
    }
  },
  computed: {
    label() {
      return this.collection
    },
    active() {
      return this.item._static?.includes(this.collection)
    },
  },
  methods: {
    async toggle() {
      if (!this.active) {
        await this.save(this.item)
        this.$bvToast.toast(`Added to ${this.collection} static items`, {variant: 'success', noCloseButton: true, solid: true})
        // keep track of state locally
        if (this.item._static) {
          this.item._static.push(this.collection)
        } else {
          this.$set(this.item, '_static', [this.collection])
        }
      } else {
        this.$bvToast.toast(`In ${this.collection} static items`, {variant: 'secondary', noCloseButton: true, solid: true})
      }
    },
    save(val) {
      if (this.busy) { throw new Error('Busy') }
      this.busy = true
      return http2.post('/api/v2/designstudio/', {
        imagedata: val,
        action: 'add-static',
        collection: this.collection,
      })
        .catch(err => {
          this.$alert.simple(`${err}\nPlease try again. Contact customer support if this persists.`, {variant: 'danger'})
          throw err
        })
        .finally(() => this.busy = false)
    }
  },
}
</script>

<style lang="scss" scoped>
.dimmed {
  opacity: $btn-disabled-opacity;
}
.icon-tag {
  transform: scale(1.1);
  transform-origin: 0 0;
}
.icon-label {
  font-size: 7.5px;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
