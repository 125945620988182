/**
 * Format a count
 * e.g. formatCount(2000, 'dog') → '2,000 dogs'
 */

export function formatCount(val, type) {
  if (val === null) { return '' }
  val = Number(val).toLocaleString()
  type = type || 'result'
  switch(val) {
    case 'NaN':
      return ''
    case '0':
      return `No ${type}s`
    case '1':
      return `1 ${type}`
    default:
      return `${val} ${type}s`
  }
}
