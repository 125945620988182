<script>
import Cart from '@/pages/projects/_id/-cart.vue'
import CartCurrent from '@/pages/projects/_id/-cart-current.js'

export default {
  name: 'CartCurrent',
  extends: Cart,
  mixins: [
    CartCurrent,
  ],
  methods: {
    onSubmit() {
      this.$router.push('/checkout')
    },
  },
}
</script>
