export function getSrc(val, preview = 'preview', updated = 'updated') {
  if (!val) { return }
  if (!URL || !val[updated]) {
    return val[preview]
  }
  try {
    const url = new URL(val[preview])
    url.searchParams.set(updated, val[updated])
    return url.toString()
  } catch (err) {
    return val[preview]
  }
}

// Return a best-guess filename from an image search result. The original
// conversion of filename into an id is lossy (slug-ified).
// see gapco-search > project/content/serializers.py > ValidateUploadedSerializer > to_internal_value
export function getFilename(item) {
  if (!item) { return }
  let regex
  const isPublisher = item.source !== 'GAA'
  const isPortal = item.royalty_code?.indexOf?.('PTL-') === 0
  if (isPublisher) {
    regex = new RegExp(`^${item.source}-`)
  } else if (isPortal) {
    regex = new RegExp(`^${item.royalty_code}-(\\d+-)?`)
  } else {
    regex = new RegExp(`^${item.royalty_code}-`)
  }
  return item.local_id?.replace?.(regex, '')
}
