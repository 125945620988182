<!--
  Modal that returns a value, stops propagation of the esc key. e.g.
  <QuickModal ref="m">
    <MyForm ref="f" @submit="$refs && $refs.m.hide('ok')" .../>
  </QuickModal>
  <script>
    const val = await this.$refs?.m.getValue(() => this.$refs?.f.validate())
  </script>
-->

<template>
  <b-modal
    v-bind="$attrs"
    v-on="$listeners"
    ref="theModal"
  >
    <!-- pass all slots from parent component -->
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"/>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'QuickModal',
  inheritAttrs: false,
  methods: {
    hide(val) {
      return this.$refs?.theModal.hide(val)
    },
    getValue(validate) {
      return new Promise((resolve, reject) => {
        const priorFocus = document.activeElement
        this.$refs.theModal.show()
        this.$refs.theModal.$on('hide', event => {
          if (event.trigger === 'ok') {
            event.preventDefault()
            validate()
              .then(data => {
                resolve(data)
                this.$refs.theModal.$off('hide')
                this.$nextTick(() => {
                  this.$refs.theModal.hide()
                })
              })
              .catch(() => {})
          } else {
            if (event.trigger === 'esc') {
              stopPropagationEsc(priorFocus)
            }
            reject('Cancelled')
            this.$refs.theModal.$off('hide')
          }
        })
      })
    },
  },
}

function stopPropagationEsc(focusedEl) {
  // prevent esc key in modal from closing quickbox
  const onEscape = function (event) {
    if (event.key !== 'Escape') { return }
    event.stopPropagation()
    document.removeEventListener('keyup', onEscape)
    focusedEl.removeEventListener('keyup', onEscape)
  }
  // the quickbox esc key handling happens in two places. we must stop
  // both because we don't know where focus will be on keyup, since focus
  // changes when the modal closes
  document.addEventListener('keyup', onEscape) // window listener in search.vue, short keypress
  focusedEl.addEventListener('keyup', onEscape) // element listener in MediaGrid.vue, long keypress
}
</script>
