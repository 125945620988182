<template>
  <div class="wrapper">
    <div class="head"
      @click="toggle()"
    >
      <div class="widget">{{mark}}</div>
      <slot name="header"/>
    </div>
    <transition name="grow">
      <div v-if="open">
        <slot/>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Collapser',
  props: {
    precollapse: Boolean,
  },
  data() {
    return {
      open: !this.precollapse,
    }
  },
  computed: {
    mark() {
      return this.open ? '−' : '+'
    }
  },
  methods: {
    toggle() {
      this.open = !this.open
    },
  },
}
</script>

<style lang="scss" scoped>
  .wrapper {
    position: relative;
  }
  .widget {
    position: absolute;
    top: 16px;
    right: 0;
    line-height: 1;
    font-size: 1.5em;
    width: 1em;
    text-align: center;
    border-radius: 50%;
    user-select: none;
    .head:hover & {
      background-color: darken($dark, 7.5%);
    }
  }
  .head {
    position: relative;
    cursor: pointer;
  }

  .grow-enter-active,
  .grow-leave-active {
    overflow: hidden;
    transition: all .4s ease;
    max-height: 300px;
  }
  .grow-enter, .grow-leave-to {
    max-height: 0;
  }
</style>
