<template>
  <div>
    <ErrorDisplay :value="error"/>
    <slot
      :data="data"
      :busy="loading"
    >
      <div v-if="loading" class="text-center">
        <b-spinner small class="text-secondary"/>
      </div>
      <slot
        name="content"
        v-if="data"
        :data="data"
      >
        <pre>{{data}}</pre>
      </slot>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'DataWrapper',
  components: {},
  props: {
    getData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: undefined,
      data: undefined,
    }
  },
  watch: {
    getData() { return this.fetchData() },
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData(config) {
      try {
        this.loading = true
        this.error = null
        this.data = await this.getData(config)
      } catch (err) {
        this.error = err
        throw err
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
