// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString#Checking_for_support_for_locales_and_options_arguments
function IntlSupport() {
  return !!(typeof Intl == 'object' && Intl && typeof Intl.DateTimeFormat == 'function');
}

export const formatters = IntlSupport() && {
  shorter: new Intl.DateTimeFormat(undefined, {
    dateStyle: 'short',
  }),
  short: new Intl.DateTimeFormat(),
  medium: new Intl.DateTimeFormat(undefined, {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric',
  }),
  // Sweden's date format is ISO 8601 and compatible with Excel https://stackoverflow.com/a/58633686/490592
  iso: new Intl.DateTimeFormat('sv-SE', {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
  }),
  noYear: new Intl.DateTimeFormat(undefined, {
    month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric',
  }),
  time: new Intl.DateTimeFormat(undefined, {
    hour: 'numeric', minute: 'numeric',
  }),
  day: new Intl.DateTimeFormat(undefined, {
    year: 'numeric', month: 'long', day: 'numeric'
  }),
  thisWeek: new Intl.DateTimeFormat(undefined, {
    weekday: 'long',
    hour: 'numeric', minute: 'numeric',
  }),
  month: new Intl.DateTimeFormat(undefined, {
    year: 'numeric', month: 'long',
  }),
}

export function datetime(val, variant = 'medium') {
  if (!val) { return val }
  const date = new Date(val)
  try {
    return formatters[variant].format(date)
  } catch (err) {
    return val
  }
}
