<template>
  <div class="media-grid" :id="'grid-'+item.id">
    <b-overlay :show="Boolean(item._processing)" spinner-small no-wrap>
      <template #overlay>
        <span
          v-if="typeof item._processing === 'string'"
        >{{item._processing}}</span>
      </template>
    </b-overlay>
    <div v-if="src" class="overflow-hidden w-100 h-100">
      <img class="media-image" :class="{faded: hidden}" :src="src" :key="src">
    </div>
    <span v-else class="centered">⏳</span>
    <div class="info" :class="{'menu-active': menuActive}">
      <div class="info-important">
        <span class="h5" v-if="hidden">
          <span class="badge badge-secondary">Unpublished</span>
        </span>
      </div>
      <div class="info-top">
        <span class="h5">
          <span class="badge badge-dark">{{item.price | price}}</span>
        </span>
      </div>
      <div class="info-bottom">
        <div class="info-artist">
          <ArtistInfo
            :item="item"
            :container="'grid-'+item.id"
          ></ArtistInfo>
        </div>
        <div class="info-extra">
          <slot name="extra"></slot>
          <QuickMenu
            :item="item"
            v-if="user && user.is_staff"
            :active.sync="menuActive"
          />
          <FavButton
            :id="item.id"
            :value="item.user_favorite"
            v-if="user"
          />
          <!--
          <CartButton
            :item="item"
          />
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  import ArtistInfo from '@/components/ArtistInfo.vue'
  import FavButton from '@/components/FavButton.vue'
  import QuickMenu from '@/components/QuickMenu.vue'
  import { getSrc } from '@/services/media.js'

  export default {
    name: 'MediaGridItem',
    components: {
      ArtistInfo,
      FavButton,
      QuickMenu,
    },
    props: {
      item: Object,
      showStatus: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        menuActive: false,
      }
    },
    computed: {
      ...mapState('user', [
        'user',
      ]),
      src() {
        return getSrc(this.item)
      },
      hidden() {
        return this.showStatus && !this.item?.published
      },
    },
  }
</script>

<style lang="scss" scoped>
.media-grid {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
  .media-image {
    width: 100%;
    transition: 0.3s opacity;
  }
  .info {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    overflow: hidden;
    &.menu-active {
      overflow: visible;
    }
    > * {
      pointer-events: all;
    }
  }
  .info-important,
  .info-top,
  .info-bottom {
    color: white;
    padding: 0 0.5rem;
  }
  .info-important {
    position: absolute;
    padding-top: 0.25rem;
  }

  @mixin item-active {
    // IE11 ignores rules with :focus-within.
    // Use a separate rule so the other selectors aren't affected.
    // https://css-tricks.com/dont-comma-separate-focus-within-if-you-need-deep-browser-support/#comment-1750402
    .menu-active &,
    .media-grid:hover & {
      @content;
    }
    .media-grid:focus-within & {
      @content;
    }
  }

  .info-top {
    position: relative;
    padding-top: 0.25rem;
    text-align: right;
    transition: 0.2s all;
    opacity: 0;
    @include item-active {
      opacity: 1;
    }
  }
  .info-bottom {
    background: linear-gradient(transparent, rgba(black,0.7));
    margin-top: auto;
    padding-top: 1rem;
    padding-bottom: 0.25rem;
    display: flex;
    align-items: center;
    transition: 0.2s all;
    transform: translateY(100%);
    @include item-active {
      transform: none; // must be "none" for z-index on child elements to work
    }
  }
  .info-artist {
    min-width: 0;
  }
  .info-extra {
    margin-left: auto;
    white-space: nowrap;
    min-width: 0;
    display: flex;
    align-items: center;
    > * {
      min-width: 0;
    }
    > * + * {
      margin-left: 0.75rem;
    }
  }
.faded {
  opacity: 0.3;
}
</style>
