<template>
  <b-navbar-nav>
    <slot name="first"/>
    <template v-for="item in itemsFiltered">
      <b-nav-item-dropdown
        v-if="item.children"
        :key="item.name"
        :text="item.name"
      >
        <DropdownChild
          v-for="child in item.children"
          :key="child.name"
          :item="child"
        />
      </b-nav-item-dropdown>
      <b-nav-item
        v-else
        :key="item.name"
        :to="item.to"
        :href="item.href"
        :exact="item.exact"
        active-class="active"
      >
        {{item.name}}
        <span v-if="item.badge" class="badge badge-primary">{{item.badge}}</span>
        <IconLinkExternal v-if="item.href"/>
      </b-nav-item>
    </template>
    <slot name="last"/>
  </b-navbar-nav>
</template>

<script>
import IconLinkExternal from '@primer/octicons/build/svg/link-external-16.svg'

import DropdownChild from '@/components/DropdownChild.vue'

export default {
  name: 'NavMenu',
  components: {
    DropdownChild,
    IconLinkExternal,
  },
  props: {
    items: Array,
  },
  computed: {
    user() { return this.$store.state.user.user },
    isStaff() { return this.$store.getters['user/isStaff'] },
    itemsFiltered() {
      return this.items.filter(({ auth }) => {
        if (!auth) { return true }
        if (!this.user) { return false }
        if (auth === 'staff' && !this.isStaff) { return false }
        return true
      })
    }
  },
}
</script>
