import { render, staticRenderFns } from "./ItemsApi.vue?vue&type=template&id=f5ad69b4&"
import script from "./ItemsApi.vue?vue&type=script&lang=js&"
export * from "./ItemsApi.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports