<template>
  <TypeaheadDynamic
    placeholder="Search Projects"
    :get-items="getItems"
    @select="onSelect"
    :value-field="textField"
    :text-field="textField"
    input-class="input-underline"
  />
</template>

<script>
import TypeaheadDynamic from '@/components/TypeaheadDynamic.vue'
import { http2 } from '@/services/http.js'
import { API_URL, APP_LIMIT_DEFAULT } from '@/services/constants.js'

export default {
  name: 'TypeaheadProjects',
  components: {
    TypeaheadDynamic,
  },
  data() {
    return {
      textField: 'label',
      idField: 'id',
    }
  },
  methods: {
    getItems(val = '') {
      return http2.get('/api/v2/myprojects/', {params: {search: val, page_size: APP_LIMIT_DEFAULT}})
        .then(response => response.data)
    },
    onSelect(item) {
      const id = item?.[this.idField]
      if (!id) { return }
      const url = `${API_URL}projects/${id}`
      document.location = url
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_env.scss';

/deep/ .input-underline {
  &, &:focus {
    background: none;
    border-color: transparent;
    border-bottom-color: $gray-500;
    color: $light;
  }
  &::placeholder {
    color: $gray-500;
  }
}
</style>
