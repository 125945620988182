import { pickBy, negate } from 'lodash'

// prepare for removal of omit in lodash 5
// https://github.com/lodash/lodash/issues/2930
// matches lodash interface

export function omitBy(obj, fn) {
  return pickBy(obj, negate(fn))
}

export function omit(obj, keys) {
  return omitBy(obj, (val, key) => keys.includes(key))
}
