<template>
  <b-form-textarea
    v-model="internal"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  name: 'InputNestedObject',
  inheritAttrs: false,
  props: {
    value: Object,
  },
  computed: {
    internal: {
      get() {
        return JSON.stringify(this.value, null, 2)
      },
      set(val) {
        try {
          this.$emit('input', JSON.parse(val))
        } catch (err) {
          this.$emit('error', err)
        }
      },
    },
  },
}
</script>
