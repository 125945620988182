<template>
  <div>
    <div class="swatches">
      <label
        v-for="color in colors"
        :key="getLabel(color)"
        class="swatch"
        :class="{active: isChecked(color)}"
        :style="`background-color: ${getLabel(color)}`"
        :title="getValue(color)"
      >
        <input
          type="checkbox"
          hidden
          :checked="isChecked(color)"
          :value="color"
          @change="onSelect(color)"
        >
      </label>
    </div>
    <div
      class="custom-control custom-checkbox"
      v-for="(option, index) in specials"
     :key="getLabel(option)"
    >
        <input
          :id="id +'-'+ index"
          type="checkbox"
          :checked="isChecked(option)"
          :value="option"
          @change="onSelect(option)"
          class="custom-control-input"
        >
        <label
          :for="id +'-'+ index"
          class="custom-control-label"
        >
          {{getLabel(option)}}
        </label>
    </div>
  </div>
</template>

<script>
import { uniqueId } from 'lodash'

export default {
  name: 'FilterColorCheckboxes',
  props: {
    value: null,
    options: Array,
    multiple: Boolean,
    preselectFirst: Boolean,
    exclusives: Array,
    typeLabel: {
      default: 'item',
    },
    id: {
      type: String,
      default: () => uniqueId('fc-'),
    },
  },
  computed: {
    specials() {
      return this.options.filter(i => !this.isInclusive(i))
    },
    colors() {
      return this.options.filter(this.isInclusive)
    },
  },
  methods: {
    onSelect(val) {
      let result
      if (this.multiple) {
        result = toArray(this.value)
        if (this.isInclusive(val)) {
          result = result.filter(this.isInclusive)
        } else {
          result = result.filter(i => i === val)
        }
        if (result.includes(val)) {
          result = result.filter(i => i !== val)
        } else {
          result.push(val)
        }
      } else {
        result = val
      }
      this.$emit('input', result)
    },
    getLabel(val) {
      return val.label || val
    },
    getValue(val) {
      return val.value || val
    },
    selectionDisplay(val) {
      let values = toArray(val)
      if (values.length === 1) {
        return this.getLabel(values[0])
          .replace(/^any /,'') // for demo only, FIXME
      } else if (values.length) {
        return `${values.length} ${this.typeLabel}s`
      }
    },
    isInclusive(item) {
      if (!this.exclusives) { return true }
      return !this.exclusives.includes(item)
    },
    isChecked(val) {
      if (this.value === undefined || this.value === null) {
        // select null value
        return val.value === null || val === null
      }

      if (this.multiple) {
        return toArray(this.value).includes(val)
      } else {
        return val === this.value
      }
    }
  },
  mounted() {
    if (
      this.preselectFirst &&
      this.value === undefined &&
      this.options.length
    ) {
      this.onSelect(this.options[0])
    }
  }
}

function toArray(val) {
  let result
  if (Array.isArray(val)) {
    result = val.slice() // copy
  } else {
    if (val === undefined || val === null) {
      result = []
    } else {
      result = [val]
    }
  }
  return result
}
</script>

<style lang="scss" scoped>
  $swatchMargin: 4px;
  .swatches {
    margin: 0 (-$swatchMargin) 5px;
  }
  .swatch {
    display: inline-block;
    width: 28px;
    height: 28px;
    margin: $swatchMargin;
    background-color: #ccc;
    border: 1px solid #333;
    border-radius: 0;
    vertical-align: middle;
    &.active {
      box-shadow: 0 0 0 2px white;
    }
  }
</style>
