import DataWrapper from '@/components/DataWrapper.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import ItemsApiGrid from '@/components/ItemsApiGrid.vue'
import LoadingButton from '@/components/LoadingButton.vue'

export default {
  install(Vue) {
    Vue.component(DataWrapper.name, DataWrapper)
    Vue.component(ErrorDisplay.name, ErrorDisplay)
    Vue.component(ItemsApiGrid.name, ItemsApiGrid)
    Vue.component(LoadingButton.name, LoadingButton)
  }
}
