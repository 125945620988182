<template>
  <div class="row no-gutters align-items-center print-contiguous">
    <div class="col-sm-4 text-center">
      <img :src="imgSrc" alt=" " class="img-fluid">
    </div>
    <div class="col-sm-8">
      <div class="row no-gutters align-items-baseline">
        <div class="col-md-9">
          <h5 class="mb-0">{{item.name}}</h5>
          <div>by {{item.config.artist}}</div>

          <div class="my-2">
            {{`${item.config.width} × ${item.config.height}`.replace(/\.00/g, '')}} Inches,
            {{item.config.substrate || 'unknown substrate'}}
            <div><a :href="getCustomizeUrl(item.id)">Customize</a></div>
          </div>

          <ErrorDisplay :value="error"/>
          <div class="d-flex my-2 flex-wrap">
            <b-form-spinbutton
              class="mr-2"
              :name="`quantity-${id}`"
              :value="item.quantity"
              @change="debouncedOnQuantity"
              :disabled="busyRemove || busyQuantity"
              required
              inline
            />
            <LoadingButton
              variant="link"
              size="sm"
              class="text-reset"
              :disabled="busyRemove || busyQuantity"
              :busy="busyRemove"
              @click="onRemove"
            >✕ Remove</LoadingButton>
          </div>
        </div>
        <div class="col-md-3 text-md-right font-weight-bold">{{currency(item.unit_price)}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'

import { API_URL } from '@/services/constants.js'
import { currency } from '@/utils/currency.js'

export default {
  name: 'CartItem',
  components: {
  },
  props: {
    item: Object,
    id: null,
    update: Function,
    remove: Function,
  },
  data() {
    return {
      busyRemove: false,
      busyQuantity: false,
      error: null,
    }
  },
  computed: {
    imgSrc() {
      return normalizeAppUrl(this.item?.imgsrc)
    },
  },
  created() {
    // delay is longer than b-form-spinbutton repeat-delay (500) +
    // repeat-interval (100) so that it does not trigger while holding down buttons
    // https://bootstrap-vue.org/docs/components/form-spinbutton#comp-ref-b-form-spinbutton
    this.debouncedOnQuantity = debounce(this.onQuantity, 650)
  },
  methods: {
    currency,
    async onQuantity(val) {
      try {
        this.busyQuantity = true
        this.error = null
        await this.update({id: this.item.id, quantity: val})
      } catch (err) {
        this.error = err
        throw err
      } finally {
        this.busyQuantity = false
      }
    },
    async onRemove() {
      const result = await this.$bvModal.msgBoxConfirm(
        `Remove "${this.item.name}" from cart?`,
        {centered: true, okTitle: 'Remove'}
      )
      if (!result) { return false }
      try {
        this.busyRemove = true
        this.error = null
        await this.remove(this.item)
      } catch (err) {
        this.error = err
        throw err
      } finally {
        this.busyRemove = false
      }
    },
    getCustomizeUrl(id) {
      return `${API_URL}art/design/${id}`
    },
  },
}

export function normalizeAppUrl(url) {
  // this function normalizes urls from APP, particularly for images in local dev
  if (!url) { return }
  if (url.match(/^http/)) {
    return url
  }
  return API_URL.replace(/\/$/, '') + url
}
</script>
