<template>
  <NavMenu
    :items="items"
  >
    <template #last>
      <b-nav-form class="ml-auto">
        <TypeaheadProjects style="min-width: 200px"/>
      </b-nav-form>
    </template>
  </NavMenu>
</template>

<script>
import NavMenu from '@/components/NavMenu.vue'
import TypeaheadProjects from '@/components/TypeaheadProjects.vue'
import { API_URL } from '@/services/constants.js'

export default {
  name: 'SiteMenuStaff',
  components: {
    NavMenu,
    TypeaheadProjects,
  },
  data() {
    return {
      items,
    }
  },
}

const items = [
  {
    "name": "Dashboard",
    "href": `${API_URL}dashboard`
  },
  {
    "name": "Projects",
    "href": `${API_URL}projects`
  },
  {
    "name": "Art",
    "to": '/',
    "exact": true
  },
  {
    "name": "Manage",
    "to": "/manage",
    "auth": "staff"
  },
  {
    "name": "Upload",
    "to": "/manage/upload/new",
    "auth": "staff"
  },
  {
    "name": "Artist Portal",
    "to": "/artist"
  }
]
</script>
