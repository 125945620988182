<script>
import Cart from '@/pages/projects/_id/-cart.vue'

export default {
  name: 'ProjectsDetail',
  extends: Cart,
  watch: {
    cart: 'setDocumentTitle',
  },
  methods: {
    setDocumentTitle(item) {
      const title = item?.project?.label || this.id
      if (title) {
        this.$nextTick(() => {
          document.title = `${title} - Great American Art`
        })
      }
    },
  },
}
</script>
