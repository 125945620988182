<template>
  <div>
    <div
      class="custom-control"
      :class="['custom-'+getType(option), {'custom-control-inline': inline}]"
      v-for="(option, index) in options"
     :key="getLabel(option)"
    >
        <input
          :id="id +'-'+ index"
          :type="getType(option)"
          :checked="isChecked(option)"
          :value="option"
          @change="onSelect(option)"
          class="custom-control-input"
        >
        <label
          :for="id +'-'+ index"
          class="custom-control-label"
        >
          {{getLabel(option)}}
        </label>
    </div>
    <slot
      name="noOptions"
      v-if="!(options && options.length)"
    >
      List is empty
    </slot>
  </div>
</template>

<script>
import { uniqueId } from 'lodash'

export default {
  name: 'FilterCheckboxes',
  props: {
    value: null,
    options: Array,
    multiple: Boolean,
    preselectFirst: Boolean,
    exclusives: Array,
    inline: Boolean,
    id: {
      type: String,
      default: () => uniqueId('fc-'),
    },
  },
  methods: {
    onSelect(val) {
      let result
      if (this.multiple) {
        result = toArray(this.value)
        if (this.isInclusive(val)) {
          result = result.filter(this.isInclusive)
        } else {
          result = result.filter(i => i === val)
        }
        if (result.includes(val)) {
          result = result.filter(i => i !== val)
        } else {
          result.push(val)
        }
      } else {
        result = val
      }
      this.$emit('input', result)
    },
    getLabel(val) {
      if (val.label) {
        const count = (val.count !== undefined) ? ` (${val.count.toLocaleString()})` : ''
        return val.label + count
      } else {
        return val
      }
    },
    getType(option) {
      return (this.multiple && this.isInclusive(option)) ? 'checkbox' : 'radio'
    },
    isInclusive(item) {
      if (!this.exclusives) { return true }
      return !this.exclusives.includes(item)
    },
    isChecked(val) {
      if (this.value === undefined || this.value === null) {
        // select null value
        return val.value === null || val === null
      }

      if (this.multiple) {
        return toArray(this.value).includes(val)
      } else {
        return val === this.value
      }
    }
  },
  mounted() {
    if (
      this.preselectFirst &&
      this.value === undefined &&
      this.options.length
    ) {
      this.onSelect(this.options[0])
    }
  }
}

function toArray(val) {
  let result
  if (Array.isArray(val)) {
    result = val.slice() // copy
  } else {
    if (val === undefined || val === null) {
      result = []
    } else {
      result = [val]
    }
  }
  return result
}
</script>
