<template>
  <div>
    <h4 class="item-title">{{item.title}}</h4>
    <div class="item-caption">
      {{item.caption}}
    </div>
    <p class="item-author">
      by <router-link :to='{name: "search", query: {q: `"${item.artist}"`}}'>{{item.artist}}</router-link>
    </p>
    <hr>
    <div class="gutter-md">

      <div class="form-row">
        <div class="col-auto label">Price</div>
        <div class="col-auto">
          {{item.price | price}}<span class="text-muted">{{(4 - item.price) | price}}</span>
        </div>
      </div>

      <div class="form-row"
        v-if="isStaff"
      >
        <div class="col-auto label">ID</div>
        <div class="col-auto">
          <router-link
            :to='{name: "search", query: {q: item.local_id, debug: (item.published ? undefined : 1)}}'
          >{{item.id}}</router-link>
        </div>
      </div>

      <div class="form-row"
        v-if="isStaff"
      >
        <div class="col-auto label">Source</div>
        <div class="col-auto">
          <router-link
            :to="{name: 'search', query: Object.assign({}, $route.query, {source: item.source})}"
          >{{item.source}}</router-link>
        </div>
      </div>

    </div>
    <hr>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MediaInfo',
  props: {
    item: Object,
  },
  computed: {
    ...mapState('user', [
      'user',
    ]),
    isStaff() {
      return this.user && this.user.is_staff
    },
  },
}
</script>

<style lang="scss" scoped>
.label {
  min-width: 4em;
}
</style>
