import { http2 } from '@/services/http.js'

export default {
  computed: {
    noun() {
      return this.$store.state.user.profile ? 'project' : 'cart'
    },
  },
  watch: {
    page: 'fetchCartItems',
    '$store.state.cart.cart': {
      handler(val) {
        this.cart = val
      },
      immediate: true,
    },
    '$store.state.cart.items': {
      handler(val) {
        if (this.page == 1) {
          this.cartItems = val
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getCart() {
      // wait for auth to resolve, to prevent cart persisting after automatic logout
      const check = await this.$store.state.appearance.checkingCart
      if (check) { return check }
      return this.$store.dispatch('cart/fetchCart')
    },
    async getCartItems(page) {
      const check = await this.$store.state.appearance.checkingCart
      if (check && page == 1) {
        return check
      } else if (page == 1) {
        return this.$store.dispatch('cart/fetchItems')
      } else if (this.cart?.token) {
        return http2.get(`/api/v2/projects/${this.cart.token}/items/`, {params: {page, page_size: this.itemsLimit}})
          .then(response => {
            if (page !== this.page) { return } // when switching pages rapidly, responses can return out of order
            this.cartItems = response.data
          })
      }
    },
  },
}
