<template>
  <b-form-select
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #first>
      <b-form-select-option :value="null" :disabled="$attrs.required">Select one</b-form-select-option>
    </template>
  </b-form-select>
</template>

<script>
export default {
  name: 'InputSelect',
  inheritAttrs: false,
}
</script>
