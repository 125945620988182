// initial state
const state = {
  searchAdminButton: null,

  checkingCart: null,

  navHeight: 0,
}

// mutations
const mutations = {
  setSearchAdminButton (state, data) {
    state.searchAdminButton = data
  },

  setCheckingCart (state, data) {
    state.checkingCart = data
  },

  setNavHeight(state, data) {
    state.navHeight = data
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
